/* ==========================================================================
	#plugins
========================================================================== */
// Bootstrap 4 media query width and height classes //ex: w-md-75, h-lg-100;
//TODO fix for Bootstrap < 4;
// @each $breakpoint in map-keys($grid-breakpoints) {
//   @include media-breakpoint-up($breakpoint) {
//     $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
//     @each $prop, $abbrev in (width: w, height: h) {
//       @each $size, $length in $sizes {
//         .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
//       }
//     }
//   }
// }


.lb-data .lb-close {
	&:before,
	&:after {
		position: absolute;
		content: '';
		left: 0;
		height: 2px;
		width: 30px;
		background: #fff;
	}
	&:before {
		transform: rotate(45deg);
	}
	&:after {
		transform: rotate(-45deg);
	}
}