.gt-header-page {
	.gt-header-info {
		position: relative;
		min-height: 605px;
		width: 60%;
		z-index: 1;
		text-align: left;
		color: $color-text;
		.gt-links-section {
			margin: 0 125px 65px 0;
			padding-top: 100px;
			text-align: right;
			li {
					margin-bottom: 20px;
				a {
					font-family: "Solido Constricted Black";
					font-size: 34px;
					line-height: 0.88;
					text-transform: uppercase;
					letter-spacing: normal;
					&:after {
						vertical-align: unset;
					}
					@media (max-width: $screen-md-max){
						font-size: 28px;
						line-height: 1;
					}
					@media (max-width: $screen-xs-max){
						font-size: 22px;
						line-height: 1.17;
					}
					@media (max-width: $screen-xs-min){
						font-size: 18px;
						line-height: 1.17;
					}
				}
				@media(max-width: $screen-md-max) {
					margin-bottom: 10px;
				}
			}
			@media (max-width: $screen-md-min){
   				margin: 0px 0 65px 0;
				padding: 30px 0 0;
			}
			@media (max-width: $screen-xs-max){
   				margin: 0px 85px 65px 0;
				margin-bottom: 30px;
			}
			@media (max-width: $screen-xs-min){
				margin-bottom: 10px;
				padding: 5px 0 10px;
			}
		}
		.gt-slogan {
			position: absolute;
			left: 0;
			bottom: 15px;
			font-family: "Solido Constricted Black";
			font-size: 42px;
			line-height: 1.19;
			color: #fff;
			@media (max-width: $screen-md-max){
				position: absolute;
				bottom: 40px;
				left: 35px;
				font-size: 34px;
			}
			@media (max-width: $screen-xs-max){
				position: absolute;
				bottom: 20px;
				left: 0;
				font-size: 24px;
			}
		}
		@media (max-width: $screen-xs-max){
			width: 100%;
			min-height: 660px;
			margin: 0 15px;
			text-align: left;
		}
		@media (max-width: $screen-xs-min){
			min-height: 550px;
		}
	}
	.gt-main-heading {
		margin-top: 0;
		margin-bottom: 15px;
		font-family: "Solido Compressed Bold";
		font-size: 70px;
		line-height: 0.98;
		text-transform: uppercase;
		@media(max-width: $screen-md-max) {
			font-size: 69px;
		}
		@media(max-width: $screen-xs-max) {
			margin-left: 0;
			font-size: 52px;
		}
		@media(max-width: $screen-xs-min) {
			width: 240px;
		}
	}
	.img-background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 740px;
		object-fit: cover;
		font-family: 'object-fit: cover;';
		z-index: 0;
		@media (max-width: $screen-xs-max) {
			height: 550px;
		}
	}		
	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 740px;
		object-fit: cover;
		font-family: 'object-fit: cover;';
		z-index: 0;
		&.gt-desktop-img {
			@media (max-width: $screen-md-min){
				display: none;
				width: auto;
				left: -20%;
			}
		}
		&.gt-tablet-img {
			display: none;
			@media (max-width: $screen-md-min){
				display: block;
			}
			@media(max-width: $screen-xs-max) {
				height: 550px;
			}
			@media (max-width: $screen-xs-min){
				display: none;
			}
		}
		&.gt-mobile-img {
			display: none;
			@media (max-width: $screen-xs-min){
				height: 550px;
				display: block;
			}
		}
	}
	&.gt-header-education {
		.gt-header-info {
			.gt-main-heading {
				margin-bottom: 50px;
				@media(max-width: $screen-md-max) {
					margin-left: 35px;
				}
				@media(max-width: $screen-xs-max) {
					margin-bottom: 0;
					margin-left: 0;
				}
			}
			.gt-slogan {
				@media(max-width: $screen-xs-max) {
					left: 0;
				}	
			}
			@media(max-width: $screen-xs-max) {
				min-height: 470px;
			}
		}
	}
	&.gt-header-intern {
		.gt-header-info {
			width: 100%;
			.gt-main-heading {
				margin-bottom: 0;
				padding: 310px 0 60px 0;
				color: #fff;
				font-family: "Solido Compressed Bold";
				font-size: 90px;
				@media (max-width: $screen-md-max){
					margin-left: 0;
					font-size: 69px;
				}
				@media (max-width: $screen-md-min){
					padding: 305px 0 60px 0;
				}
				@media (max-width: $screen-xs-max){
					width: 90%;
					padding: 0;
					font-size: 52px;
					letter-spacing: -1.25px;
				}
			}
			@media (max-width: $screen-md-max){
				padding: 0 0 0 35px;
			}
			@media (max-width: $screen-xs-max){
				padding: 180px 0 0 0;
				min-height: 470px;
			}
		}
	}
	&.gt-header-faq {
		position: relative;
		margin-bottom: 50px;
		padding-top: 100px;
		.gt-main-heading {
			font-family: "Solido Compressed Bold";
			font-size: 90px;
			line-height: 0.98;
			color: $color-text;
			& + .gt-main-heading {
				color: $color-primary;
			}
		}
		@media (max-width: $screen-md-max) {
			padding: 70px 25px 0;
			.gt-main-heading {
				margin-bottom: 20px;
				font-size: 75px;
				letter-spacing: -1.25px;
			}
		}
		@media (max-width: $screen-xs-max) {
			padding: 60px 15px 0;
			.gt-main-heading {
				margin-bottom: 15px;
				font-size: 50px;
				letter-spacing: -1.25px;
			}
		}
	}
	&.gt-header-courses {
		position: relative;
		padding-top: 100px;
		color: $color-text;
		.gt-main-heading {
			margin-bottom: 30px;
			font-family: "Solido Compressed Bold";
			font-size: 90px;
			line-height: 1;
		}
		.gt-main-subheading {
			max-width: 560px;
			margin-bottom: 60px;
			font-family: "Solido Constricted Black";
			font-size: 28px;
			line-height: 1;
		}		
		@media (max-width: $screen-md-max) {
			padding: 70px 25px 0;
			.gt-main-heading {
				margin-bottom: 20px;
				font-size: 75px;
				letter-spacing: -1.25px;
			}
			.gt-main-subheading {
				margin-bottom: 40px;
				font-size: 20px;
				letter-spacing: -.1px;
			}
		}		
		@media (max-width: $screen-md-min) {
			.gt-main-heading {
				width: 420px;
			}
		}
		@media (max-width: $screen-xs-max) {
			padding: 60px 15px 0;
			.gt-main-heading {
				width: 285px;
				margin: 5px 0 15px;
				font-size: 50px;
				letter-spacing: -1.25px;
			}
			.gt-main-subheading {
				margin-bottom: 30px;
				font-size: 18px;
				line-height: 24px;
				letter-spacing: -.06px;
			}
		}
	}
	&.gt-header-companies {
		position: relative;
		color: $color-text;
		margin-bottom: 50px;
		padding-top: 100px;
		.gt-main-heading {
			margin-bottom: 30px;
			font-family: "Solido Compressed Bold";
			font-size: 90px;
			line-height: 1;
			@media(max-width: $screen-md-max) {
				margin-left: 35px;
				font-size: 69px;
			}
			@media(max-width: $screen-xs-max) {
				margin-left: 15px;
				font-size: 52px;
				letter-spacing: -1.25px;
			}
			@media(max-width: $screen-xs-min) {
				width: 240px;
			}
		}
		.gt-main-subheading {
			max-width: 630px;
			margin-bottom: 60px;
			font-family: "Solido Constricted Black";
			font-size: 28px;
			line-height: 1;
			@media(max-width: $screen-md-max) {
				margin-left: 35px;
				font-size: 20px;
				width: 445px;
				letter-spacing: -0.1px;
				line-height: 1.3;
			}
			@media(max-width: $screen-xs-max) {
				width: auto;
				margin: 0 15px;
				font-size: 18px;
			}
		}
	}
	&.gt-header-english-site {
		.gt-header-info {
			min-height: 605px;
			.gt-main-heading {
				width: 475px;
				margin-bottom: 70px;
				padding-top: 190px;
				font-size: 80px;
				@media(max-width: $screen-md-max) {
					width: 500px;
					margin-left: 35px;
					padding-top: 150px;
					font-size: 69px;
				}
				@media(max-width: $screen-md-min) {
					width: 465px;
				}
				@media(max-width: $screen-xs-max) {
					margin-left: 0;
					padding-top: 70px;
					width: 240px;
					font-size: 52px;
				}
			}
			@media(max-width: $screen-md-max) {
				padding-top: 105px;
				width: auto;
				.gt-copyright {
					right: 35px;
				}
			}
			@media(max-width: $screen-xs-max) {
				width: auto;
				min-height: 470px;
				font-size: 52px;
				.gt-copyright {
					right: 0;
				}
			}
		}
	}
	&.gt-header-industry {
		font-size: 90px;
		.gt-main-heading {
			padding-top: 260px;
			width: 350px;
			margin-bottom: 115px;
			@media(max-width: $screen-md-max) {
				width: 390px;
				font-size: 75px;
			}
			@media(max-width: $screen-xs-max) {
				padding-top: 160px;
				width: 260px;
				font-size: 52px;
			}
		}
		@media(max-width: $screen-md-max) {
			padding-left: 30px;
			.gt-header-info {
				.gt-slogan {
					left: 0;
				}
			}
		}
		@media(max-width: $screen-xs-max) {
			padding-left: 0;
			.gt-header-info {
				width: 100%;
				min-height: 470px;
			}
			img {
				height: 550px;
			}
		}
	}

	&.gt-header-company {
		position: relative;
		padding-top: 100px;
		.gt-header-info {
			min-height: 0;
			.gt-main-heading {
				font-size: 90px;
				@media(max-width: $screen-md-max) {
					margin-left: 35px;
					font-size: 75px;
				}
				@media(max-width: $screen-xs-max) {
					margin-left: 0;
					font-size: 52px;
				}
			}
			.gt-main-subheading {
				max-width: 560px;
				margin-bottom: 30px;
				font-family: "Solido Condensed ExtraBold";
				font-size: 34px;
				line-height: 1;
				@media(max-width: $screen-md-max) {
					margin-left: 35px;
					font-size: 30px;
				}
				@media(max-width: $screen-xs-max) {
					margin-left: 0;
					font-size: 27px;
				}
			}
			.gt-company-tags {
				margin-bottom: 70px;
				li {
					display: inline-block;
					margin-right: 25px;
					a {
						display: block;
						border: 1px solid #000;
						padding: 10px 15px 7px;
						font-family: "Solido Compressed Bold";
						font-size: 28px;
						line-height: 1;
						letter-spacing: -0.5px;
						text-transform: uppercase;
						cursor: default;
						@media(max-width: $screen-md-max) {
							font-size: 24px;
						}
						@media(max-width: $screen-xs-max) {
							font-size: 22px;
						}
					}
					@media(max-width: $screen-md-max) {
						margin-bottom: 20px;
					}
					@media(max-width: $screen-xs-max) {
						margin-right: 15px;
					}
				}
				@media(max-width: $screen-md-max) {
					margin-left: 35px;
				}
				@media(max-width: $screen-xs-max) {
					margin-left: 0;
				}
			}
		}
	}

	&.gt-header-careers {
		.gt-header-info {
			.gt-main-heading {
				margin-bottom: 30px;
				letter-spacing: -1.25px;
				line-height: 0.98;
				@media(max-width: $screen-md-max) {
					margin-left: 35px;
					margin-bottom: 0;
				}
				@media(max-width: $screen-xs-max) {
					width: 400px;
					margin-left: 0;
					margin-bottom: 0;
				}
				@media(max-width: $screen-xs-min) {
					width: 250px;
				}
			}
			.gt-slogan {
				@media(max-width: $screen-xs-max) {
					left: 0;
				}	
			}
			.gt-links-section {
				@media(max-width: $screen-xs-max) {
					text-align: left;
					a {
						&:after {
							vertical-align: sub;
						}
					}
				}
			}
			@media(max-width: $screen-xs-max) {
				min-height: 470px;
			}
		}	
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 740px;
			object-fit: cover;
			font-family: 'object-fit: cover;';
			z-index: 0;
			&.gt-desktop-img {
				@media (max-width: $screen-md-min){
					display: none;
					width: auto;
					left: -20%;
				}
			}
			&.gt-tablet-img {
				display: none;
				@media (max-width: $screen-md-min){
					display: block;
				}
				@media(max-width: $screen-xs-max) {
					height: 550px;
				}
				@media (max-width: $screen-xs-min){
					display: none;
				}
			}
			&.gt-mobile-img {
				display: none;
				@media (max-width: $screen-xs-min){
					height: 550px;
					display: block;
				}
			}
		}
	}
	&.gt-header-parents-teachers {
		.gt-header-info {
			padding-top: 130px;
			.gt-main-heading {
				width: 690px;
				font-size: 90px;
				line-height: 0.98;
				letter-spacing: -1.25px;
				@media(max-width: $screen-md-max) {
					width: 500px;
					font-size: 69px;
				}
				@media(max-width: $screen-xs-max) {
					width: 300px;
					font-size: 52px;
				}
			}
			p {
				color: #fff;
			}
			@media(max-width: $screen-md-max) {
				padding: 55px 0 0 35px;
			}
			@media(max-width: $screen-xs-max) {
				padding: 55px 0 0 0;
				min-height: 470px;
				font-size: 52px;
			}
		}
	}
	&.gt-header-campaign {
		.gt-header-info {
			padding-top: 350px;
			min-height: 605px;
			.gt-main-heading {
				margin-bottom: 60px;
				color: #fff;
				@media (max-width: $screen-md-max){
					margin-left: 0;
					font-size: 69px;
				}
				@media (max-width: $screen-xs-max){
					width: 90%;
					padding: 0;
					font-size: 52px;
					letter-spacing: -1.25px;
				}
			}
			@media (max-width: $screen-md-max){
				padding: 300px 0 0 35px;
				width: 62%;
			}
			@media(max-width: $screen-md-min) {
				.gt-copyright {
					bottom: 10px;
					right: -290px;
				}
			}
			@media (max-width: $screen-xs-max){
				padding: 180px 0 0 0;
				width: auto;
				min-height: 470px;
				.gt-copyright {
					right: 0;
				}
			}
		}
	}
}

.gt-body-page {
	&.gt-body-intern {
		padding-top: 75px;
		.gt-intern-faq {
			margin: 0 -35px 150px;
			@media(max-width: $screen-xs-max) {
				margin: 0 0 100px;
			}
		}
		.map-container {
			@media(max-width: $screen-md-max) {
				margin: 0 -35px;
			}
			@media(max-width: $screen-xs-max) {
				margin: 0 0;
			}
		}
		.gt-boxes {
			&.gt-boxes-important {
				@media(max-width: $screen-md-min) {
					margin-bottom: 0;
				}
				.gt-box-important {
					@media (max-width: $screen-md-min){
						margin-bottom: 50px;
					}
					@media (max-width: $screen-xs-max){
						margin-bottom: 100px;
					}
				}
			}
		}
		@media(max-width: $screen-md-max) {
			padding: 50px 35px 0;
		}
		@media(max-width: $screen-md-min) {
			right: -250px;
		}
		@media(max-width: $screen-xs-max) {
			right: 0;
			padding: 50px 0 0;
			margin-bottom: 100px;
		}
	}
	&.gt-body-courses {
		@media (max-width: $screen-md-max) {
			padding: 0 20px;
		}
		@media (max-width: $screen-xs-max) {
			padding: 0 15px;
		}
	}
	&.gt-body-company-map {
		display: flex;
	}
	&.gt-body-english-site {
		padding-top: 160px;

		.gt-box-important {
			margin-bottom: 160px;
			.content {
				@media(max-width: $screen-md-max) {
					margin: 0 auto;
				}
			}
			&:nth-of-type(3n-1) {
				.content {
					@media(max-width: $screen-md-max) {
						margin: 0 auto;
					}
				}
			}
		}
	}
	&.gt-body-education {
		@media(max-width: $screen-xs-max) {
			padding: 70px 0 100px;
		}
	}
	&.gt-body-industry {
		padding-top: 150px;
	}
	&.gt-body-company {
		h3 {
			font-family: "Solido Condensed ExtraBold";
			margin-bottom: 30px;
			font-size: 50px;
			line-height: 0.98;
			@media(max-width: $screen-md-max) {
				margin-bottom: 20px;
				font-size: 37px;
			}
		}
		p {
			margin-bottom: 40px;
			font-size: 28px;
			line-height: 1.36;
			span {
				display: block;
			}
			@media(max-width: $screen-md-max) {
				font-size: 24px;
				margin-bottom: 20px;
			}
			@media(max-width: $screen-md-max) {
				font-size: 20px;
			}
		}
		ul {
			padding-inline-start: 40px;
			margin-bottom: 50px;
			li {
				font-size: 28px;
				position: relative;
				margin-bottom: 20px;
				&:before {
				    content:"";
				    position: absolute;
				    top: 15px;
				    left: -20px;
				    background: #000;
				    height: 3px;
				    width: 3px;
				    border-radius: 50%;
					@media(max-width: $screen-xs-max) {
						top: 10px;
					}
				}
				@media(max-width: $screen-md-max) {
					font-size: 24px;
				}
				@media(max-width: $screen-xs-max) {
					font-size: 20px;
				}
			}
		}
		.gt-company-details {
			margin-bottom: 150px;
			.gt-company-info {
				width: 55%;
				float: left;
				padding-right: 40px;
				@media(max-width: $screen-md-max) {
					width: 100%;
					float: unset;
					padding: 0;
					margin-bottom: 70px;
				}
				@media(max-width: $screen-xs-max) {
					margin-bottom: 50px;
				}
			}
			.gt-company-contacts {
				float: left;
				width: 45%;
				padding-left: 40px;
				border-left: 1px solid #000;
				.logo-company {
					margin-bottom: 50px;				
					@media(max-width: $screen-md-max) {
						display: block;
					}	
					@media(max-width: $screen-md-max) {
						width: 286px;
						height: 52px;
						object-fit: cover;
						font-family: 'object-fit: cover;';
					}
				}
				@media(max-width: $screen-md-max) {
					width: 100%;
					float: unset;
					padding: 70px 0 0 0;
					border-left: unset;
					border-top: 1px solid #000;
					.contact-info {
						width: 50%;
						float: left;
					}
				}
				@media(max-width: $screen-xs-max) {
					padding: 50px 0 0 0;
					.contact-info {
						float: unset;
						width: 100%;
					}
				}
			}
		}
		@media(max-width: $screen-md-max) {
			margin: 0 35px;
		}
		@media(max-width: $screen-xs-max) {
			margin: 0 15px;
		}
	}
	&.gt-body-404 {
		text-align: center;
		color: #ffffff;
		h3 {
			position: relative;
			margin-bottom: 50px;
			font-family: "Solido Condensed ExtraBold";
			font-size: 50px;
			line-height: 0.98;
			letter-spacing: -0.71px;
			z-index: 1;
			@media(max-width: $screen-xs-max) {
				font-size: 32px;
			}
		}
		.icon-404 {
			margin-bottom: 50px;
			position: relative;
			z-index: 1;
			@media(max-width: $screen-xs-max) {
				width: 235px;
				height: 105px;
			}
		}
		.img-background {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			font-family: 'object-fit: cover;';
		}
		.link {
			display: inline-block;
			position: relative;
			z-index: 1;
			margin-bottom: 300px;
			color: #fff;
			&:after {
				background-image: url("../../images/text-link-arrow-white.svg");
			}
			&:hover {
				border-bottom: 2px solid #fff;
			}
		}
		@media(max-width: $screen-xs-max) {
			margin-top: 50px;
		}
	}
	&.gt-body-campaign {
		padding-top: 160px;
		.file {
			display: block;
		    font-size: 28px;
    		line-height: 1.36;
			@media(max-width: $screen-md-max) {
				font-size: 24px;
			}
			@media(max-width: $screen-xs-max) {
				display: block;
				font-size: 20px;
			}
		}
		@media(max-width: $screen-md-max) {
			padding-top: 70px;
			margin: 0 35px;
		}
		@media(max-width: $screen-xs-max) {
			padding-top: 50px;
			margin: 0 15px;
		}
	}
	&.gt-body-companies {
		.gt-btn-primary {
			margin-bottom: 50px;
			@media(max-width: $screen-xs-max) {
				font-size: 28px;
			}
			@media(max-width: $screen-xs-min) {
			}
		}
		.gt-switch-container {
			text-align: left;
		}
		.companies-container {
			height: 0;
			visibility: hidden;
			opacity: 0;
			overflow: hidden;
			transition: opacity 1s;
			&.has-flag {
				height: auto;
				visibility: visible;
				opacity: 1;
				transition: opacity 1s;
			}
			&:first-child {
				text-align: center;
				@media(max-width: $screen-md-max) {
					margin: 0 35px;
				}
				@media(max-width: $screen-xs-max) {
					margin: 0 15px;
				}
			}
		}
		@media(max-width: $screen-md-max) {
			&:first-child {
				margin: 0 35px;
			}
		}
		@media(max-width: $screen-xs-max) {
			&:first-child {
				margin: 0 15px;
			}
		}
	}
	&.gt-body-parents-teachers {
		@media(max-width: $screen-md-max) {
			margin: 0 30px;
		}
		@media(max-width: $screen-xs-max) {
			margin: 0 15px;
		}
	}
	&.gt-body-careers {
		@media(max-width: $screen-md-max) {
			margin: 0 30px;
		}
		@media(max-width: $screen-xs-max) {
			margin: 0 15px;
			.gt-important {
				padding-top: 0;
			}
		}
	}
}

.gt-content-page {
	&.gt-content-faq {
		@media (max-width: $screen-md-max) {
			margin-bottom: 100px;
			padding: 0 20px;
		}
		@media (max-width: $screen-xs-max) {
			padding: 0 15px;
		}
	}
}

.gt-footer-page {

}
.gt-header-magazin{
	.gt-header-info{
		width: 100%;
		.gt-main-heading{
			color: white;
			padding: 300px 0 0 0;
			font-size: 90px;
			margin-bottom: 0;
		}
		.gt-slogan{
			bottom: 40px;
			@media(max-width: 767px){
				bottom: 20px;
			}
		}
		.gt-copyright{
			bottom: 40px;
			@media(max-width: 767px){
				bottom: 0;
			}
		}
		@media(max-width: 1199px){
			padding: 0 0 0 35px;
			.gt-main-heading{
				font-size: 69px;
			}
		}
		@media(max-width: 768px){
			.gt-main-heading{
				font-size: 52px;
				letter-spacing: -1.25px;
				padding: 0;
			}
		}
		@media(max-width: 767px){
			min-height: 450px;
			.gt-slogan{
				left: 0;
			}
			padding: 180px 0 0;
			margin: 0 15px;
		}
	}
	margin-bottom: 100px;
}
.gt-body-magazin{
	h2{
		font-family: "Solido Compressed Bold";
  		font-size: 90px;
  		font-weight: bold;
  		line-height: 0.98;
  		letter-spacing: -1.25px;
		color: var(--black);
		margin-bottom: 70px;
	}
	@media(max-width: 1199px){
		max-width: 792px;
		margin: 0 auto;
		h2{
			font-size: 75px;
		}
	}
	@media(max-width: 792px){
		max-width: 768px;
	}
	@media(max-width: 768px){
		h2{
			font-size: 52px;
		}
		max-width: 347px;
	}
	@media(max-width: 400px){
		max-width: 320px;
	}
}

.gt-header-artikel{
	.gt-header-info{
		width: 100%;
		.gt-main-heading{
			color: white;
			padding: 0;
			font-size: 90px;
			margin-bottom: 0;
			width: 996px;
			position: absolute;
			bottom: 150px;
			max-height: 264px;
			overflow: hidden;
		}
		.gt-slogan{
			bottom: 40px;
			@media(max-width: 767px){
				bottom: 20px;
			}
		}
		.gt-copyright{
			bottom: 40px;
			@media(max-width: 767px){
				bottom: 0;
			}
			.no-img{
				display: none;
			}
		}
		@media(max-width: 1199px){
			padding: 0 0 0 35px;
			.gt-main-heading{
				font-size: 69px;
				width: 75%;
			}
		}
		@media(max-width: 768px){
			.gt-main-heading{
				font-size: 52px;
				letter-spacing: -1.25px;
				//padding: 0;
				//padding-bottom: 40px;
				bottom: 100px;
				max-height: 350px;
			}
		}
		@media(max-width: 767px){
			min-height: 450px;
			.gt-slogan{
				left: 0;
			}
			padding: 180px 0 0;
			margin: 0 15px;
		}
	}
	margin-bottom: 100px;
	&.has-no-image{
		img{
			display: none;
		}
		.gt-header-info{
			min-height: 376px;
			max-height: 464px;
			overflow: hidden;
			.gt-main-heading{
				position: unset;
				color: #000;
				padding: 100px 0 0;
				margin-bottom: 50px;
				max-height: 364px;
				overflow: hidden;
			}
			.gt-slogan{
				color: #000;
				bottom: unset;
				top: unset;
				position: unset;
			}
			.gt-copyright{
				bottom: unset;
				top: 40px;
				.gt-helper-container{
					a{
						&:nth-child(3){
							margin-right: 10px;
						}
					}
				}
				.has-img{
					display: none;
				}
				.no-img{
					display: inline-block;
				}
			}
		}
		@media(max-width: 767px){
			.gt-header-info{
				padding: 0;
				min-height: 280px;
				.gt-slogan{
					top: 230px;
				}
			}
		}
		@media(max-width: 560px){
			.gt-header-info{
				min-height: 346px;
				.gt-main-heading{
					width: 90%;
				}
				.gt-slogan{
					top: 326px;
				}
			}
		}

	}
}
.gt-body-artikel{
	.gt-artikel-information{
		max-width: 792px;
		margin: 0 auto;
		margin-bottom: 140px;
		.gt-artikel-text{
			margin-bottom: 20px;
			.artikel-text-first{
				font-family: "Solido Bold";
  				font-size: 34px;
  				font-weight: bold;
  				line-height: 1.3;
  				letter-spacing: -0.5px;
  				margin-bottom: 50px;
			}

			ul {
				list-style: inherit;
				font-size: 24px;
				font-family: "Solido Book" ;
  				line-height: 1.36;
  				margin-bottom: 50px;

			}

			p{
				font-family: "Solido Book" ;
  				font-size: 28px;
  				line-height: 1.36;
  				margin-bottom: 50px;
			}
			h3{
				font-family: "Solido Condensed ExtraBold";
  				font-size: 50px;
  				font-weight: 800;
  				font-stretch: condensed;
  				font-style: normal;
  				line-height: 0.98;
  				letter-spacing: -0.71px;
  				margin-bottom: 30px;
			}
			img{
				margin-bottom: 50px;
			}
			@media(max-width: 1199px){
				.artikel-text-first{
					font-size: 24px;
				}
				p{
					font-size: 24px;
				}
				h3{
					font-size: 37px;
				}
			}
			@media(max-width: 767px){
				.artikel-text-first{
					font-size: 20px;
				}
				p{
					font-size: 20px;
				}
				h3{

				}
			}
		}
		.gt-artikel-social{
			h4{
				font-family: "Solido Condensed ExtraBold";
  				font-size: 34px;
  				font-weight: 800;
  				font-stretch: condensed;
  				font-style: normal;
  				line-height: 1.18;
  				letter-spacing: -0.25px;
  				margin-bottom: 30px;
			}
			.gt-socials{
				position: unset;
			}
		}
		@media(max-width: 900px){
			margin: 0 35px 140px;
			.gt-artikel-text{
				img{
					width: 100%;
				}
			}
		}
		@media(max-width: 767px){
			margin: 0 15px 80px;
		}
	}
	.gt-artikel-same-tags{
		h1{
			font-family: "Solido Compressed Bold";
  			font-size: 90px;
  			font-weight: bold;
  			line-height: 0.98;
  			letter-spacing: -1.25px;
  			margin-bottom: 70px;
		}
		@media(max-width: 1199px){
			h1{
				font-size: 75px;
				margin:0 35px 70px;
			}
			.gt-magazin-artikel{
				margin:0 35px;
			}
		}
		@media(max-width: 767px){
			h1{
				font-size: 52px;
				margin: 0 15px 50px;
			}
			.gt-magazin-artikel{
				justify-content: center;
				.gt-artikel-element{
					flex-basis: 51%;
				}
			}
		}
		@media(max-width: 400px){
			.gt-magazin-artikel{
				margin: 0;
				.gt-artikel-element{
					flex-basis: 100%;
				}
			}
		}
	}
}
.gt-index-artikel{
	margin-bottom: 100px;
	h1{
		font-family: "Solido Compressed Bold";
  		font-size: 90px;
  		font-weight: bold;
  		line-height: 0.98;
  		letter-spacing: -1.25px;
  		margin-bottom: 90px;
  		color: #d2d2d2;
  		text-align: center;
	}
	@media(max-width: 1199px){
		h1{
			font-size: 75px;
		}
	}
	@media(max-width: 480px){
		h1{
			font-size: 52px;
			line-height: 0.96;
		}
	}
}
.gt-btns-under{
	display: flex;
	justify-content: space-between;
}

.gt-body-registration{
	cursor: default;

	.gt-registration-information{
		max-width: 1200px;
		margin: 0 auto;
		margin-bottom: 140px;
		@media(max-width: 767px) {
			margin-bottom: 50px;
		}

		.container {
			position: relative;
			overflow: hidden;
			width: 100%;
			-webkit-transition: height 1s ease;
			-moz-transition: height 1s ease;
			-o-transition: height 1s ease;
			transition: height 1s ease;

		}
		.gt-registration-extendable-button{
			/*Animations*/
			position: relative;
			overflow: hidden;

			margin: 0 auto;

			.container{
				background: black;
				height: 36px;
				min-width: 280px;
				max-width: 340px;
				font-size: 22px;
				@media(max-width: 767px){
					min-width: 220px;
					width: 220px;
					height: 30px;
					font-size: 20px;
				}

			}

			.container:hover{
				cursor: pointer;
			}


			p {
				font-family: "Solido Book";
				height: 100%;
				color: white;
				//padding-top: 2px;
				text-align: center;
				padding: 2px;
			}

			.gt-copyright{
				@media(max-width: 440px){
					position: relative;
					float: right;
				}

			}

			.gt-helper-container{
				height: 40px;

				@media(max-width: 767px){
					height: 30px;

				}
				.icon-svg{
					height: 40px;
					@media(max-width: 767px){
						height: 30px;
					}

				}
			}



		}
		.gt-registration-text{
			-webkit-transition: all 0.5s ease;
			-moz-transition: all 0.5s ease;
			-o-transition: all 0.5s ease;
			transition: all 0.5s ease;
			&.hide{
				height: 0;
				visibility: hidden;
			}

			.distanced {
				margin-bottom: 70px;
			}

			.grid-wrapper {
				display: grid;
				grid-template-columns: 1fr;
				grid-template-rows: 1fr 1fr;
				grid-gap: 10px;

				margin-left: 40px;

				border: 0;
				border-left: 1px;
				border-color: black;
				border-style: solid;


				.label-row {
					//max-height: 24px;
					font-size: 25px;
					p {
						margin-bottom: 0;
					}
				}

				.input-row {
					//height: 24px;
					margin-bottom: 5px;

					input {
						height: 20px;
						width: 160px;
						border-radius: 4px;
						margin-right: 10px;
						background-color: rgb(219, 210, 237);
						color: black;
					}
					select {
						height: 20px;
						width: 160px;
						border-radius: 4px;
						margin-right: 10px;
						background-color: rgb(219, 210, 237);
						text-align: center;
					}
				}

				.error-row {
					ul {
						margin-bottom: 5px;
					}
					li {
						font-size: 14px;
						color: red;
						height: 14px;
					}
				}

				.checkbox-row {
					//height: 14px;

					p {
						font-size: 14px;
					}
				}

				.label-wrapper {
					height: 0;
				}

				div {
					margin-left: 40px;
					border-left: 1px;
					border-color: black;
				}

				p {
					text-align: left;
					font-size: 18px;
				}

				.circle {
					width: 24px;
					height: 24px;
					-webkit-border-radius: 12px;
					-moz-border-radius: 12px;
					border-radius: 12px;
					background: black;
					margin-left: -92px;

					p {
						color: white;
						width: 100%;
						height: 100%;
						text-align: center;
						vertical-align: middle;
						padding-top: 1px;
					}
				}
				//grid-auto-rows: minmax(100px, auto);
			}

			margin-bottom: 20px;
			ul {
				list-style: inherit;
				font-size: 24px;
				font-family: "Solido Book" ;
				line-height: 1.36;
				margin-bottom: 50px;

			}
			p{
				font-family: "Solido Book" ;
				font-size: 25px;
				text-align: center;
				line-height: 1.36;
				margin-bottom: 10px;
			}

			h3{
				font-family: "Solido Condensed ExtraBold";
				font-size: 50px;
				font-weight: 800;
				font-stretch: condensed;
				font-style: normal;
				line-height: 0.98;
				letter-spacing: -0.71px;
				margin-bottom: 30px;
			}
			img{
				margin-bottom: 50px;
			}
			@media(max-width: 1199px){
				.artikel-text-first{
					font-size: 24px;
				}
				p{
					font-size: 24px;
				}
				h3{
					font-size: 37px;
				}
			}
			@media(max-width: 767px){
				.artikel-text-first{
					font-size: 20px;
				}
				p{
					font-size: 20px;
				}
				h3{

				}
			}
		}
	}
}
