.wr-social-sharing {
	color: #0f3851;
	.wr-title {
		font-size: 1.3em;
		margin-bottom: 1.45em;
		font-weight: 600;
	}
	.social-buttons {
		margin: 0 0 1.875rem;
		padding: 0;
		font-size: 0;
		li {
			display: inline-block;
			font-size: 1.25rem;
			color: inherit;
			a {
				text-decoration: none;
				padding: 0 30px 0 0;
				color: inherit;
				transition: .3s all;
				&:hover {
					text-decoration: none;
					color: $color-primary;
				}
			}
		} 
	}
	@media print {
		display: none !important;
	}
}