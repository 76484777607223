// general settings
$wrapper-width: 1200px;
$container-width: 1280px;

// colors
$color-primary: #DBD2ED;  // #snuff purple
$color-secondary: #DBD2ED; // #snuff purple
$color-transport: #FEBC9A; // #wax flower
$color-energy: #C8C9BB; // #kangaroo
$color-health: #FFF391; // #witch haze
$color-fashion: #BCD6C9; // #gum leaf
$color-live: #CFE7F7; // #tropical blue
$color-error: #eb1f1f;
$color-success: #54c46a;

$color-background: #ffffff;
$color-text: #000000;

// fonts and font-styles
$font-primary: 'Solido Book', sans-serif;