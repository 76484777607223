.wr-cookiebanner {
	display: none;
	position: fixed;
	bottom: 0;
	width: 100%; 
	z-index: 50;
	.container {
		flex-wrap: wrap;
	}

	.wr-cookie-content {
		padding: 30px 0; 
		font-weight: 500;
		background: $color-primary;
		color: #fff;
		.wr-cookie-text {
			p {
				margin: 0;
			}
			a {
				color: inherit;
				text-decoration: underline;
			}
		}
		.wr-btns {
			.wr-btn {
				background: #000;
				color: #fff;
				i {
					font-size: 0.8em;
					margin-left: 15px;
				}
			}
			a#wr__cookiebanner_close {
				color: #000;
				i {
				display: inline-block;
				transition: .3s all;
					&:hover {
						transform: rotate(90deg);
						color: #fff;
					}
				}
			}
		}
	}
	@media print {
		display: none !important; 
	}
}

