.gt-secondary-heading {
	margin-bottom: 90px;
	font-family: "Solido Compressed Bold";
	color: #d2d2d2;
	font-size: 90px;
	line-height: 0.98;
	text-align: center;
	text-transform: uppercase;
	@media (max-width: $screen-md-max){
		font-size: 75px;
		line-height: 1;
		letter-spacing: -1.25px;
	}
	@media (max-width: $screen-xs-min){
		margin: 0 25px 90px;
		font-size: 52px;
		line-height: 0.96;
	}
}
.gt-accent-video {
	.gt-accent-info {
		position: relative;
		width: 60%;
		min-height: 605px;
		z-index: 1;
		text-align: right;
		.gt-links-section {
			padding: 90px 125px 65px 0;
			li {
				margin-bottom: 20px;
				.link {
					font-family: "Solido Constricted Black";
					font-size: 34px;
					line-height: 0.88;
					text-transform: uppercase;
					&:after {
						vertical-align: initial;
					}
					@media (max-width: $screen-md-max){
						font-size: 28px;
						letter-spacing: normal;
						line-height: 1;
					}
					@media (max-width: $screen-xs-max){
						white-space: pre-line;
						font-size: 22px;
						line-height: 1.17;
					}
					@media (max-width: $screen-xs-min){
						font-size: 18px;
						line-height: 1.17;
					}
				}
			}
			@media (max-width: $screen-md-min){
				padding: 30px 0 40px;
			}
			@media (max-width: $screen-xs-max){
				margin-bottom: 10px;
			}
			@media (max-width: $screen-xs-min){
				padding: 5px 0 10px;
			}
		}
		.gt-main-heading {
			&.video-heading {
				width: 500px;
				margin-left: auto;
				margin-bottom: 40px;
				font-family: "Solido Compressed Bold";
				font-size: 90px;
				line-height: 0.98;
				text-transform: uppercase;
				color: $color-text;
				span {
					line-height: 0.98;
					color: #fff;
				}
				@media (max-width: $screen-md-max){
					width: 400px;
					margin-bottom: 85px;
					font-size: 75px;
					line-height: 1;
					letter-spacing: -1.25px;
				}
				@media (max-width: $screen-xs-max){
					width: 300px;
					font-size: 60px;
					margin-left: 0;
				}
				@media (max-width: $screen-xs-min){
					width: 280px;
					font-size: 52px;
				}
			}
		}
		.gt-slogan {
			font-family: "Solido Constricted Black";
			margin-top: 0;
			text-align: left;
			font-size: 42px;
			line-height: 1.19;
			color: #fff;
			@media (max-width: $screen-md-max){
				position: absolute;
				bottom: 40px;
				left: 35px;
				font-size: 34px;
			}
			@media (max-width: $screen-xs-max){
				position: absolute;
				bottom: 20px;
				left: 0;
				font-size: 24px;
			}
		}
		.gt-copyright {
			position: absolute;
			bottom: 5px;
			right: -480px;
			font-size: 0;
			.icon {
				width: 50px;
				height: 50px;
				margin-bottom: 10px;
				&:first-child {
					margin-right: 10px;
				}
				@media (max-width: $screen-md-min){
					width: 40px;
					height: 40px;
					margin-bottom: 5px;
				}
				@media (max-width: $screen-xs-max){
					width: 30px;
					height: 30px;
				}
			}
			p {
				color: #fff;
				font-size: 10px;
				text-align: right;
			}
			@media (max-width: $screen-md-max){
				right: -380px;
			}
			@media (max-width: $screen-md-min){
				right: -280px;
			}
			@media (max-width: $screen-xs-max){
				right: 30px;
			}
		}
		@media (max-width: $screen-xs-max){
			width: 100%;
			min-height: 660px;
			margin-left: 15px;
			text-align: left;
		}
		@media (max-width: $screen-xs-min){
			min-height: 470px;
		}
	}
	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 740px;
		object-fit: cover;
		font-family: 'object-fit: cover;';
		z-index: 0;
		&.gt-desktop-img {
			@media (max-width: $screen-md-min){
				display: none;
				width: auto;
				left: -20%;
			}
		}
		&.gt-tablet-img {
			display: none;
			@media (max-width: $screen-md-min){
				display: block;
			}
			@media (max-width: $screen-xs-min){
				display: none;
			}
		}
		&.gt-mobile-img {
			display: none;
			@media (max-width: $screen-xs-min){
				height: 550px;
				display: block;
			}
		}
	}
}

.gt-accent-about {
	padding: 0 50px;
	.gt-secondary-heading {
		margin-bottom: 90px;
	}
	.gt-about-item {
		position: relative;
		margin-bottom: 100px;
		.background-square {
			position: absolute;
			top: 30px;
			left: 220px;
			width: 475px;
			height: 385px;
			@media (max-width: $screen-md-max){
				width: 380px;
				height: 300px;
			}
			@media (max-width: $screen-md-min){
				left: 165px;
				width: 275px;
				height: 260px;
			}
			@media (max-width: $screen-xs-max){
				top: 15px;
				left: 60%;
				width: 255px;
				height: 180px;
				transform: translateX(-50%);
			}
			@media (max-width: $screen-xs-min){
				left: 35px;
				transform: none;
			}
		}
		&:nth-of-type(1) {
			.background-square {
				background-color: #fed0b8;
			}
			.copyright {
				color: #fff;
			}
			.content {
				.about-info {
					.title {
						width: 485px;
						margin-bottom: 55px;
						@media(max-width: $screen-md-max) {
							width: 360px;
							margin-bottom: 10px;
						}
						@media(max-width: $screen-xs-max) {
							width: 320px;
							margin-bottom: 10px;
						}
					}
				}
			}
		}
		&:nth-of-type(2) {
			.background-square {
				background-color: #d9d9cf;
			}
			.content {
				.about-info {
					.title {
						margin-bottom: 60px;
						@media(max-width: $screen-xs-max) {
							margin-bottom: 10px;
						}
					}
				}
			}
		}
		&:nth-of-type(3) {
			.background-square {
				background-color: #fff7b2;
			}
			.content {
				.about-info {
					.title {
						margin-bottom: 130px;
						@media(max-width: $screen-md-max) {
							margin-bottom: 10px;
						}
					}
				}
			}
		}
		&:nth-of-type(4) {
			.background-square {
				background-color: #d0e2d9;
			}
			.content {
				.about-info {
					.title {
						margin-bottom: 60px;
						@media(max-width: $screen-md-max) {
							margin-bottom: 10px;
						}
					}
				}
			}
		}
		&:nth-of-type(5) {
			.background-square {
				background-color: #ddeef9;
			}
			.content {
				.about-info {
					.title {
						margin-bottom: 50px;
						@media(max-width: $screen-md-max) {
							margin-bottom: 10px;
						}
					}
				}
			}
		}
		.content {
			position: relative;
			text-transform: uppercase;
			color: $color-text;
			.about-info {
				position: relative;
				float: left;
				margin: 0 0 0 50px;
				z-index: 1;
				.subheading {
					display: block;
					margin-top: 50px;
					margin-bottom: 30px;
					font-family: "Solido Constricted Black";
					font-size: 30px;
					line-height: 0.87;
					@media (max-width: $screen-md-min){
						margin-top: 40px;
						font-size: 22px;
					}
					@media (max-width: $screen-xs-max){
						margin-top: 20px;
						font-size: 16px;
					}
				}
				.title {
					width: 525px;
					font-family: "Solido Compressed Bold";
					margin-bottom: 85px;
					font-size: 77px;
					line-height: 0.99;
					hyphens: none;
					@media (max-width: $screen-md-max){
						width: 100%;
						font-size: 67px;
					}
					@media (max-width: $screen-md-min){
						margin-bottom: 20px;
						font-size: 57px;
					}
					@media (max-width: $screen-xs-max){
						width: 85%;
						font-size: 37px;
					}
				}
				.more {
					float: right;
					font-size: 24px;
					line-height: 1;
					&:after {
						vertical-align: bottom;
						margin-left: 20px;
					}
					@media (max-width: $screen-md-max){
						font-size: 22px;
						letter-spacing: 1.5px;
					}
					@media (max-width: $screen-xs-max){
						float: unset;
						font-size: 18px;
					}
				}
				@media (max-width: $screen-md-max){
					width: 45%;
					margin: 0 0 0 40px;
				}
				@media (max-width: $screen-xs-max){
					float: unset;
					width: 100%;
					margin: 0;
					text-align: left;
				}
			}
			.picture {
				float: left;
				img {
					position: relative;
					width: 525px;
					height: 375px;
					object-fit: cover;
					font-family: 'object-fit: cover;';
					z-index: 1;
					@media (max-width: $screen-md-max){
						width: 470px;
						height: 320px;
					}
					@media (max-width: $screen-md-min){
						width: 330px;
						height: 235px;
					}
					@media (max-width: $screen-xs-max){
						width: 255px;
						height: 180px;
					}
				}
				@media (max-width: $screen-xs-max) {
					float: unset;
					width: 255px;
					height: 180px;
					margin: 0 auto 20px;
					margin-bottom: 20px;
				}
				@media (max-width: $screen-xs-min) {
					margin: 0 0 20px;
				}
			}
		}
		&:nth-child(odd) {
			.content {
				padding-top: 30px;
				text-align: right;
				.about-info {
					float: right;
					margin: 0 50px 0 0;
					.subheading {
						margin-top: 0;
					}				
					@media (max-width: $screen-xs-max) {
						float: unset;
						width: 100%;
						margin: 0;
					}
				}
				.picture {
					float: right;
					@media (max-width: $screen-xs-max) {
						float: unset;
						text-align: center;
					}
					@media (max-width: $screen-xs-min) {
						margin: 0 0 20px auto;
					}
				}
			}
			.background-square {
				top: -23px;
				left: unset;
				right: 200px;
				height: 338px;
				@media (max-width: $screen-md-max){
					width: 380px;
					height: 280px;
				}
				@media (max-width: $screen-md-min){
					top: -15px;
					right: 145px;
					width: 305px;
					height: 230px;
				}
				@media (max-width: $screen-xs-max){
					right: 70%;
					width: 200px;
					height: 170px;
					transform: translateX(50%);
				}
				@media (max-width: $screen-xs-min){
					right: 90px;
					transform: none;
				}
			}
		}
	}
	@media (max-width: $screen-md-max){
		padding: 0 30px;
	}
	@media (max-width: $screen-xs-max){
		padding: 0 15px;
	}
}

.gt-accent-important {
	.gt-secondary-heading {
		margin-bottom: 100px;
	}
}