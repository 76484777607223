/* ------------------------------------------------------------ *\
	#boxes
\* ------------------------------------------------------------ */
.gt-boxes {
	&:before, 
	&:after {
	    display: table;
	    content: "";
	    clear: both;
	}
	.gt-box {
		float: left;		
	}
	&.gt-boxes-quicklinks {
		margin-bottom: 60px;
		padding: 75px 0;
		.gt-box-quicklink {
			margin-left: 10px;
			&:nth-child(1) {
				.icon {
					width: 115px;
					height: 131px;	
					@media (max-width: $screen-md-max){
						height: 95px;
						width: 90px;
					}
					@media (max-width: $screen-xs-min){
						width: 75px;
						height: 80px;
					}
				}
			}
			&:nth-child(2) {
				.icon {
					height: 150px;
					width: 115px;
					@media (max-width: $screen-md-max){
						height: 95px;
						width: 75px;
					}		
					@media (max-width: $screen-xs-min){
						width: 65px;
						height: 80px;
					}
				}		
			}
			&:nth-child(3) {
				.icon {
					width: 132px;
					height: 126px;
					@media (max-width: $screen-md-max){
						height: 95px;
						width: 105px;
					}	
					@media (max-width: $screen-xs-min){
						width: 90px;
						height: 80px;
					}		
				}	
			}
			&:nth-child(4) {
				.icon {
					width: 135px;
					height: 115px;
					@media (max-width: $screen-md-max){
						height: 95px;
						width: 120px;
					}
					@media (max-width: $screen-xs-min){
						width: 100px;
						height: 80px;
					}
				}
				.occupation {
					margin: 60px 0 0 0;
					@media (max-width: $screen-md-max){
						margin: 20px 0 0;
					}
				}	
			}
			a {
				align-items: center;
				.icon {
					float: left;
					margin-right: 10px;
					@media (max-width: $screen-md-max){
						float: unset;
						margin-right: 0;
					}
				}
				.occupation {
					float: left;
					margin: 60px 0 0;
					font-family: "Solido Compressed Bold";
					font-size: 45px;
					line-height: 0.78;
					text-transform: uppercase;
					@media (max-width: $screen-md-max){
						margin: 20px 0 0;
						float: unset;
						font-size: 33px;
						line-height: 1;
						letter-spacing: -0.68px;
					}
					@media (max-width: $screen-xs-max){
						font-size: 28px;
						line-height: 1.18;
						letter-spacing: -0.65px;
					}
				}
			}
			&:last-child {
				margin-right: 0;
			}
			@media (max-width: $screen-md-max){
				width: 25%;
				margin: 0;
				text-align: center;
			}
			@media (max-width: $screen-xs-max){
				width: 50%;
				margin-bottom: 40px;
			}
		}
		@media(max-width: $screen-xs-max) {
			margin-bottom: 50px;
			padding: 50px 0;
		}
	}
	&.gt-boxes-important {
		margin-bottom: 100px;
		.gt-box-important {
			position: relative;
			.background-square {
				position: absolute;
				background-color: #f2f2f2;
			}
			&:nth-of-type(3n+1) {
				.content {
					@media (max-width: $screen-md-min){
						margin-left: auto;
					}
					@media (max-width: $screen-xs-max){
						margin: 0 auto;
					}
				}
				.background-square {
					top: 53px;
					left: -60px;
					width: 400px;
					height: 430px;
					@media (max-width: $screen-md-max){
						left: -10px;
						width: 305px;
						height: 390px;
					}
					@media (max-width: $screen-md-min){
						right: 0;
						left: unset;
						width: 345px;
						height: 370px;
					}
					@media (max-width: $screen-xs-max){
						right: unset;
						top: 40px;
						left: 50%;
						transform: translateX(-50%);
					}
					@media (max-width: $screen-xs-min){
						width: 290px;
						height: 310px;

					}
				}
			}
			&:nth-of-type(3n-1) {	
				.content {
					margin: 0 auto;
					@media (max-width: $screen-md-min){
						margin: 0 0 0 auto;
					}
					@media (max-width: $screen-xs-max){
						margin: 0 auto;
					}
				}
				.background-square {
					top: 200px;
					left: 5px;
					width: 382px;
					height: 371px;
					@media (max-width: $screen-md-max){
						width: 310px;
						height: 340px;
					}
					@media (max-width: $screen-md-min){
						top: -10px;
						left: unset;
						right: 16%;
						width: 255px;
						height: 335px;
					}
					@media (max-width: $screen-xs-max){
						top: -10px;
						left: 50%;
						right: unset;
						transform: translateX(-60%);
					}
					@media (max-width: $screen-xs-min){
						width: 240px;
						height: 255px;
					}
				}

			}
			&:nth-of-type(3n) {
				.content {
					margin-left: auto;
					@media (max-width: $screen-xs-max){
						margin: 0 auto;
					}
				}
				.background-square {
					top: 20px;
					left: 40px;
					width: 300px;
					height: 452px;
					@media (max-width: $screen-md-max){
						width: 310px;
						height: 340px;
					}
					@media (max-width: $screen-md-min){
						top: 175px;
						left: 15px;
						width: 345px;
						height: 335px;
					}
					@media (max-width: $screen-xs-max){
						top: 100px;
						left: 50%;
						transform: translateX(-50%);
					}
					@media (max-width: $screen-xs-min){
						width: 290px;
						height: 325px;
					}
				}
			}	

			.content {
				position: relative;
				width: 315px;
				min-height: 550px;
				padding: 30px;
				box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.15);
				background-color: #fff;
				color: #000;
				.subheading {
					margin-bottom: 35px;
					font-family: "Solido Constricted Black";
					text-transform: uppercase;
					font-size: 26px;
					line-height: 1;
					letter-spacing: 0.5px;
					@media (max-width: $screen-md-max){
						font-size: 24px;
					}
					@media (max-width: $screen-xs-max){
						font-size: 20px;
					}
				}
				.title {
					margin-bottom: 30px;
					font-family: "Solido Condensed ExtraBold";
					text-transform: uppercase;
					font-size: 50px;
					line-height: 0.98;
					letter-spacing: -0.71px;
					@media (max-width: $screen-md-max){
						font-size: 37px;
					}
					@media (max-width: $screen-xs-max){
						font-size: 32px;
					}
				}
				.description {
					font-size: 24px;
					line-height: 1.3;
					@media (max-width: $screen-md-max){
						font-size: 21px;
					}
					@media (max-width: $screen-xs-max){
						margin-bottom: 65px;
						font-size: 20px;
					}
				}
				.more {
					position: absolute;
					width: 235px;
					bottom: 30px;
					text-transform: uppercase;
					font-size: 24px;
					line-height: 1;
					&:after {
						vertical-align: bottom;
					}
					@media (max-width: $screen-md-max){
						font-size: 22px;
						letter-spacing: 1.5px;
					}
					@media (max-width: $screen-xs-max){
						width: 230px;
						font-size: 18px;
					}
				}
				@media (max-width: $screen-md-max){
					width: 270px;
					min-height: 470px;
					padding: 20px;
				}
				@media (max-width: $screen-xs-max){
					width: 245px;
					min-height: 400px;
					padding: 15px;
				}
			}
			@media (max-width: $screen-md-min){
				margin-bottom: 100px;
			}
		}
		@media(max-width: $screen-xs-max) {
			margin-bottom: 0;
		}
	}
	&.gt-boxes-education {
		display: flex;
		flex-wrap: wrap;
		.gt-box-education {
			text-align: left;
			margin-bottom: 50px;
			.image-container {
				position: relative;
				display: inline-block;
				margin-bottom: 40px;
				.main-image {
					position: relative;
					z-index: 1;
					object-fit: cover;
					@media(max-width: $screen-md-max) {
						width: 330px;
						height: 235px;
					}
					@media(max-width: $screen-xs-max) {
						width: 300px;
						height: 200px;
					}
					@media(max-width: $screen-xs-min) {
						width: 258px;
						height: 184px;
					}
				}
				.background-square {
					position: absolute;
					&.fashion{
						background-color: #d0e2d9;
					}
					&.purple{
						top: -25px;
						left: 10px;
						height: 247px;
						width: 356px;
						background-color: #dbd2ed;
						@media(max-width: $screen-md-max) {
							width: 330px;
							height: 229px;
						}
						@media(max-width: $screen-xs-max) {
							width: 288px;
							height: 209px;
						}
						@media(max-width: $screen-xs-min) {
							width: 258px;
							height: 179px;
						}
					}
					&.live{
						top: -25px;
						left: 10px;
						height: 247px;
						width: 356px;
						background-color: #ddeef9;
						@media(max-width: $screen-md-max) {
							width: 330px;
							height: 229px;
						}
						@media(max-width: $screen-xs-max) {
							width: 288px;
							height: 209px;
						}
						@media(max-width: $screen-xs-min) {
							width: 258px;
							height: 179px;
						}
					}
					&.health {
						top: 20px;
						left: -15px;
						height: 250px;
						width: 347px;
						background-color: #fff7b2;
						@media(max-width: $screen-md-max) {
							width: 344px;
							height: 239px;
						}
						@media(max-width: $screen-xs-max) {
							width: 312px;
							height: 204px;
						}
						@media(max-width: $screen-xs-min) {
							width: 272px;
							height: 184px;
						}
					}
					&.transport {
						top: 35px;
						left: -10px;
						height: 237px;
						width: 376px;
						background-color: #fed0b8;
						@media(max-width: $screen-md-max) {
							width: 349px;
							height: 219px;
						}
						@media(max-width: $screen-xs-max) {
							width: 303px;
							height: 191px;
						}
						@media(max-width: $screen-xs-min) {
							width: 273px;
							height: 171px;
						}
					}
					&.energy {
						top: -10px;
						left: 10px;
						height: 217px;
						width: 356px;
						background-color: #c8c9bb;		
						@media(max-width: $screen-md-max) {
							width: 329px;
							height: 200px;
						}			
						@media(max-width: $screen-xs-max) {
							width: 289px;
							height: 180px;
						}			
						@media(max-width: $screen-xs-min) {
							width: 259px;
							height: 160px;
						}			
					}
				}
				.copyright {
					color: #fff;
				}
			}
			.content {
				text-align: left;
				.title {
					margin-bottom: 10px;
					font-family: "Solido Condensed ExtraBold";
					font-size: 28px;
					line-height: 1.07;
					text-transform: uppercase;
					@media(max-width: $screen-md-max) {
						font-size: 26px;
					}			
					@media(max-width: $screen-xs-max) {
						font-size: 22px;
					}
				}
				.richtext {
					margin-bottom: 35px;
					font-family: "Solido Book";
					font-size: 24px;
					line-height: 1.3;
					@media(max-width: $screen-md-max) {
						font-size: 24px;
					}			
					@media(max-width: $screen-xs-max) {
						font-size: 20px;
					}
				}
			}
			&:nth-of-type(3n+1) {
				.background-square {
					&.fashion{
						top: 10px;
						left: 10px;
						height: 247px;
						width: 356px;
						@media(max-width: $screen-md-max) {
							width: 334px;
							height: 232px;
						}
						@media(max-width: $screen-xs-max) {
							width: 301px;
							height: 201px;
						}
						@media(max-width: $screen-xs-min) {
							width: 261px;
							height: 181px;
						}
					}
				}
				@media(max-width: $screen-md-max) {
					text-align: left;
				}
				@media(max-width: $screen-xs-max) {
					text-align: center;
				}
			}
			&:nth-of-type(3n-1) {
				text-align: center;
				.background-square {
					&.fashion{
						top: 35px;
						left: -10px;
						height: 237px;
						width: 376px;
						@media(max-width: $screen-md-max) {
							width: 345px;
							height: 217px;
						}
						@media(max-width: $screen-xs-max) {
							width: 302px;
							height: 191px;
						}
						@media(max-width: $screen-xs-min) {
							width: 272px;
							height: 171px;
						}
					}
				}
				@media(max-width: $screen-md-max) {
					text-align: left;
				}
				@media(max-width: $screen-xs-max) {
					text-align: center;
				}
			}
			&:nth-of-type(3n) {
				text-align: right;
				.background-square {
					&.fashion{
						top: -10px;
						left: 10px;
						height: 217px;
						width: 356px;
						@media(max-width: $screen-md-max) {
							width: 334px;
							height: 198px;
						}
						@media(max-width: $screen-xs-max) {
							width: 302px;
							height: 171px;
						}
						@media(max-width: $screen-xs-min) {
							width: 262px;
							height: 151px;
						}
					}
				}
				@media(max-width: $screen-md-max) {
					text-align: left;
				}
				@media(max-width: $screen-xs-max) {
					text-align: center;
				}
			}
		}
		@media(max-width: $screen-md-max) {
			padding-left: 30px;
		}
		@media(max-width: $screen-xs-max) {
			padding: 15px;
		}
	}
	&.gt-boxes-careers {
		padding: 75px 0;
		.gt-box-careers {
			color: #000;
			.heading {
				min-height: 139px;
				margin-bottom: 30px; 
				.icon-svg {
					display: inline-block;
					width: 111px;
					height: 139px;
					margin-right: 20px;
					vertical-align: middle;
					@media(max-width: $screen-md-max) {
						width: 120px;
						height: 115px;
					}
					@media(max-width: $screen-xs-max) {
						display: block;
						margin: 0 auto 30px;
					}
				}
				.title {
					display: inline-block;
					font-family: "Solido Compressed Bold";
					font-size: 45px;
					line-height: 0.78;
					letter-spacing: -0.64px;
					text-transform: uppercase;
					vertical-align: middle;
					@media(max-width: $screen-md-max) {
						font-size: 33px;
					}
					@media(max-width: $screen-xs-max) {
						font-size: 28px;
					}
				}
				@media(max-width: $screen-md-max) {
					display: inline-block;
					width: 50%;
					min-height: unset;
					vertical-align: middle;
				}
				@media(max-width: $screen-xs-max) {
					display: block;
					width: 100%;
					text-align: center;
				}
			}
			.content {
				.subheading {
					margin-bottom: 10px;
					font-family: "Solido Condensed ExtraBold";
					font-size: 28px;
					line-height: 1.07;
					letter-spacing: -0.2px;
					text-transform: uppercase;
					@media(max-width: $screen-md-max) {
						font-size: 26px;
					}
					@media(max-width: $screen-xs-max) {
						font-size: 22px;
					}
				}
				.richtext {
					margin-bottom: 30px;
					font-size: 24px;
					line-height: 1.3;
					@media(max-width: $screen-md-max) {
						font-size: 24px;
					}
					@media(max-width: $screen-xs-max) {
						font-size: 20px;
					}
				}
				@media(max-width: $screen-md-max) {
					display: inline-block;
					width: 50%;
					min-height: unset;
					vertical-align: middle;
				}
				@media(max-width: $screen-xs-max) {
					display: block;
					width: 100%;
				}
			}
			&:nth-of-type(2) {
				.icon-svg {
					width: 128px;
					@media(max-width: $screen-md-max) {
						width: 120px;
						height: 95px;
					}
				}
			}
			&:nth-of-type(3) {
				.icon-svg {
					width: 133px;
					margin-right: 10px;
					@media(max-width: $screen-md-max) {
						width: 120px;
						height: 95px;
						margin-right: 20px;
					}
					@media(max-width: $screen-xs-max) {
						width: 120px;
						height: 95px;
						margin: 0 auto 20px;
					}
				}
			}
			@media(max-width: $screen-md-max) {
				margin-bottom: 75px;
				padding: 0;
				font-size: 0;
			}
			@media(max-width: $screen-xs-max) {
				float: unset;
				width: 100%;
			}
		}
	}
}