/* ------------------------------------------------------------ *\
	#education #Ausbildung
\* ------------------------------------------------------------ */
.gt-education-category {
	color: $color-text;
	.category-title {
		margin: 100px 0 70px 0;
		> .richtext {
			-ms-hyphens: initial;
			hyphens: initial;
		}
		h2 {
			margin-bottom: 30px;
			font-family: "Solido Compressed Bold";
			font-size: 90px;
			line-height: 0.98;
			hyphens: auto;
			text-transform: uppercase;
			@media(max-width: $screen-md-max) {
				padding-left: 35px;
				font-size: 75px;
				letter-spacing: -1.25px;
				line-height: 1;
			}
			@media(max-width: $screen-xs-max) {
				width: 280px;
				padding: 0 15px;
				font-size: 52px;
			}
		}

		.category-subtitle {
			margin-bottom: 15px;
			font-family: "Solido Bold";
			font-size: 28px;
			line-height: 1.36;
			@media(max-width: $screen-md-max) {
				padding: 0 35px;
				font-size: 24px;
				line-height: 1.25;
			}
			@media(max-width: $screen-xs-max) {
				padding: 0 15px;
				font-size: 20px;
			}
		}
		.richtext {
			margin-bottom: 20px;
			font-family: "Solido Bold";
			font-size: 36px;
			line-height: 1.16;
			@media(max-width: $screen-md-max) {
				padding: 0 35px;
				font-size: 30px;
				line-height: 1;
			}
			@media(max-width: $screen-xs-max) {
				padding: 0 15px;
				font-size: 26px;
			}
		}
		.category-subheading {
			margin-bottom: 100px;
			font-family: "Solido Condensed ExtraBold";
			font-size: 24px;
			line-height: 1.04;	
			@media(max-width: $screen-md-max) {
				padding: 0 35px;
				font-size: 30px;
				line-height: 1;
			}
			@media(max-width: $screen-xs-max) {
				padding: 0 15px;
				font-size: 24px;
			}
		}
		@media(max-width: $screen-md-max) {
			margin: 50px 0 70px;
		}
		@media(max-width: $screen-xs-max) {
			margin: 0 0 30px 0;
		}
	}
	.gt-school-educations {
		margin-bottom: 100px;
		.gt-school-education-item {
			border-bottom: 1px solid #000;
			padding: 30px;
			p {
				float: left;
				margin: 0;
				padding: 0;
				&.heading {
					width: 35%;
					font-family: "Solido Constricted Black";
					font-size: 28px;
					line-height: 1;
					@media(max-width: $screen-md-max) {
						width: 80%;
						margin-bottom: 10px;
						font-size: 20px;
					}
					@media(max-width: $screen-xs-max) {
						width: 100%;
						margin-bottom: 10px;
						font-size: 18px;
					}
				}
				&.description {
					width: 55%;
					font-family: "Solido Book";
					font-size: 28px;
					line-height: 1;
					@media(max-width: $screen-md-max) {
						width: 80%;
						font-size: 20px;
					}
					@media(max-width: $screen-xs-max) {
						width: 100%;
						margin-bottom: 30px;
						font-size: 18px;
					}
				}
			}
			.link {
				float: right;
				@media(max-width: $screen-md-max) {
					margin-top: -20px;
				}
				@media(max-width: $screen-xs-max) {
					float: unset;
				}
			}
			&:first-child {
				border-top: 1px solid #000;	
			}
			@media(max-width: $screen-xs-max) {
				padding: 15px;
			}
		}
		@media(max-width: $screen-md-max) {
			margin: 30px;
		}
		@media(max-width: $screen-xs-max) {
			margin: 15px;
		}
	}
}

/* ------------------------------------------------------------ *\
	#education #intern #Ausbildung-Einzelseite
\* ------------------------------------------------------------ */
.gt-intern-description {
	margin-bottom: 150px;
	> div {
		&:nth-of-type(1) {
			h3 {
				text-transform: uppercase;
				&:nth-of-type(1) {
					padding-bottom: 50px;
				}
			}
		}
		&:nth-of-type(2) {
			.richtext {
				border-left: 2px solid #000;
				padding-left: 65px;
				@media(max-width: $screen-md-max) {
					border-left: unset;
					border-top: 2px solid #000;
					padding-left: 0;
				}
			}
		}
		.richtext {
			color: #000;
			font-size: 28px;
			line-height: 1.36;
			p {
				margin-bottom: 50px;
				@media(max-width: $screen-md-max) {
					font-size: 24px;
				}
				@media(max-width: $screen-xs-max) {
					margin-bottom: 30px;
					font-size: 20px;
				}
			}
			ul {
				padding-inline-start: 40px;
				margin-bottom: 50px;
				li {
					position: relative;
					margin-bottom: 20px;
					&:before {
					    content:"";
					    position: absolute;
					    top: 15px;
					    left: -20px;
					    background: #000;
					    height: 3px;
					    width: 3px;
					    border-radius: 50%;
						@media(max-width: $screen-xs-max) {
							top: 10px;
						}
					}
					@media(max-width: $screen-md-max) {
						font-size: 24px;
					}
					@media(max-width: $screen-xs-max) {
						font-size: 20px;
					}
				}
			}
			h3 {
				text-transform: uppercase;
				margin-bottom: 30px;
				color: #000;
				font-family: "Solido Condensed ExtraBold";
				font-size: 50px;
				line-height: 0.98;
				&:nth-of-type(1) {
					padding-top: 40px;
					@media(max-width: $screen-md-max) {
						padding-bottom: 30px;
						font-size: 37px;
					}
					@media(max-width: $screen-xs-max) {
						padding-bottom: 0;
						font-size: 32px;
						letter-spacing: -0.75px;
						line-height: 1.09;
					}
				}
				@media(max-width: $screen-md-max) {
					font-size: 37px;
				}
				@media(max-width: $screen-xs-max) {
					padding: 0;
					font-size: 32px;
				}
			}
			.icon {
				float: left;
				width: 150px;
				fill: #dbd2ed;
				@media(max-width: $screen-md-max) {
					width: 75px;
					height: 95px;
					margin-right: 25px;
				}
				@media(max-width: $screen-xs-max) {
					float: unset;
				}
				&.purple {
					fill: #dbd2ed;
				}
				&.transport {
					fill: $color-transport;
				}
				&.energy {
					fill: $color-energy;
				}
				&.health {
					fill: $color-health;
				}
				&.fashion {
					fill: $color-fashion;
				}
				&.live {
					fill: $color-live; 
				}
			}
		}
	}
	@media(max-width: $screen-xs-max) {
		margin-bottom: 50px;
		padding: 0 15px;
	}
}
.gt-intern-faq {
	background-color: #bcd6c9;
	margin-bottom: 150px;
	.section-title {
		padding: 60px 0 50px 0;
		color: #fff;
		font-family: "Solido Compressed Bold";
		font-size: 90px;
		line-height: 0.98;
		text-align: center;
		@media(max-width: $screen-md-max) {
			font-size: 75px;
		}
		@media(max-width: $screen-xs-max) {
			font-size: 52px;
		}
	}
	.questions {
		padding-bottom: 150px;
		.question-item {
			.title {
				margin-bottom: 25px;
				font-family: "Solido Condensed ExtraBold";
				font-size: 34px;
				line-height: 1.18;
				@media(max-width: $screen-md-max) {
					font-size: 28px;
				}
				@media(max-width: $screen-xs-max) {
					font-size: 24px;
				}
			}
			.richtext {
				margin-bottom: 50px;
				font-size: 28px;
				line-height: 1.36;
				@media(max-width: $screen-md-max) {
					font-size: 24px;
				}
				@media(max-width: $screen-xs-max) {
					font-size: 20px;
				}
			}
		}
		@media(max-width: $screen-xs-max) {
			padding-bottom: 0;
		}
	}
	&.purple {
		background-color: #dbd2ed;
	}
	&.transport {
		background: $color-transport;
	}
	&.energy {
		background: $color-energy;
	}
	&.health {
		background: $color-health;
	}
	&.fashion {
		background: $color-fashion;
	}
	&.live {
		background: $color-live; 
	}
	@media(max-width: $screen-md-max) {
		padding: 0 30px;
	}
	@media(max-width: $screen-xs-max) {
		padding: 0 15px;
	}
}
.gt-intern-apply {
	position: relative;
	margin-bottom: 150px;
	.wrapper {
		position: relative;
	}
	.background-square {
		position:absolute;
		z-index: 0;
		background-color: #d0e2d9;
		&:nth-of-type(1) {
			top: 40px;
			left: 0;   
			width: 235px;
			height: 160px;
			z-index: 5;
			@media(max-width: $screen-xs-max) {
				width: 122px;
				height: 145px;
			}
			@media(max-width: $screen-xs-min) {
				width: 122px;
				height: 145px;
			}
		}
		&:nth-of-type(2) {
			top: 0;
			left: 140px;
		  	width: 415px;
  			height: 244px;
			opacity: 0.5;
			z-index: 4;
			@media(max-width: $screen-xs-max) {
				left: 80px;
				width: 216px;
				height: 219px;
			}
			@media(max-width: $screen-xs-min) {
				width: 216px;
				height: 219px;
			}
		}
		&.purple {
			background-color: #dbd2ed;
		}
		&.transport {
			background: $color-transport;
		}
		&.energy {
			background: $color-energy;
		}
		&.health {
			background: $color-health;
		}
		&.fashion {
			background: $color-fashion;
		}
		&.live {
			background: $color-live; 
		}
	}
	.heading {
		color: #000;
		position: relative;
		font-family: "Solido Condensed ExtraBold";
		font-size: 50px;
		z-index: 10;
		margin: 0 0 0 20px;
		&:nth-of-type(1) {
			padding-top: 20px;
			@media(max-width: $screen-md-max) {
				padding-top: 75px;
			}
			@media(max-width: $screen-xs-min) {
				padding-top: 0;
			}
		}
		&:nth-of-type(2) {
			margin-bottom: 25px;
			@media(max-width: $screen-md-max) {
				margin-bottom: 50px;
			}
		}
		@media(max-width: $screen-md-max) {
			margin: 0;
			font-size: 40px;
			letter-spacing: -0.55px;
			line-height: 1.5;
		}
		@media(max-width: $screen-md-min) {
			margin: 0;
			font-size: 37px;
			letter-spacing: -0.75px;
			line-height: 1.14;
		}
		@media(max-width: $screen-xs-min) {
			font-size: 32px;
			line-height: 1.09;
		}
	}
	.description {
		margin-left: 20px;
		font-size: 28px;
		p {
			float: left;
			position: relative;
			z-index: 10;
			@media(max-width: $screen-md-max) {
				width: 100%;
				margin-bottom: 50px;
			}
		}
		a {
			float: right;
			position: relative;
			z-index: 5;
		}
		@media(max-width: $screen-md-max) {
			margin-left: 50px;
			font-size: 24px;
		}
		@media(max-width: $screen-xs-min) {
			margin-left: 0;
			font-size: 20px;
		}
	}
	@media(max-width: $screen-md-max) {
		padding: 0 30px;
	}
	@media(max-width: $screen-xs-max) {
		padding: 0 15px;
	}
}
.gt-carousel-intern {
	position: relative;
	margin: 150px 0;
	.gt-carousel-item {
		position: relative;
		.background-square {
			position: absolute;
			left: 50%;
			width: 473px;
			height: 338px;
			background: #bcd6c9;
			transform: translateX(-50%);
			&.purple {
				background-color: #dbd2ed;
			}
			&.transport {
				background: $color-transport;
			}
			&.energy {
				background: $color-energy;
			}
			&.health {
				background: $color-health;
			}
			&.fashion {
				background: $color-fashion;
			}
			&.live {
				background: $color-live; 
			}
			@media(max-width: $screen-md-max) {
				width: 305px;
				height: 230px;
				left: 60%;
			}
			@media(max-width: $screen-xs-max) {
				width: 200px;
				height: 170px;
				left: 39%;
			}
		}
		.content {
			padding: 0 70px;
			position: relative;
			z-index: 10;
			.title {
				float: right;
				width: 50%;
				margin-top: 35px;
				margin-right: 74px;
				color: $color-text;
				text-align: right;
				font-family: "Solido Compressed Bold";
				font-size: 90px;
				letter-spacing: -1.25px;
				line-height: 0.98;
				text-transform: uppercase;
				vertical-align: middle;
				@media(max-width: $screen-md-max) {
					width: 47%;	
					margin-right: 20px;
					margin-top: 40px;
					font-size: 57px;
					line-height: 1.02;
				}
				@media(max-width: $screen-xs-max) {
					width: 60%;	
					float: unset;
					margin: 0 auto;
					font-size: 37px;
					text-align: center;
				}
				@media(max-width: $screen-xs-min) {
					width: 60%;	
					float: unset;
					margin: 0 auto;
					font-size: 37px;
					text-align: right;
				}
			}
			.image-container {
					position: relative;
					float: right;
					width: 453px;
					height: 100%;
					margin-top: 60px;
					margin-left: auto;
					object-fit: cover;
					font-family: 'object-fit: cover;';
					vertical-align: middle;
					@media(max-width: $screen-md-max) {
						width: 320px;
						height: 240px;
						margin-top: 20px;
					}
					@media(max-width: $screen-xs-max) {
						float: unset;
						margin: 10px auto 50px;
						width: 250px;
						height: 180px;
					}
					@media(max-width: $screen-xs-min) {
						margin: 10px 15px 50px auto;
					}
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					font-family: 'object-fit: cover;';
				}
			}
			@media(max-width: $screen-md-max) {
				padding: 0 50px;
			}
			@media(max-width: $screen-md-min) {
				padding: 0 0 0 50px;
			}
			@media(max-width: $screen-xs-max) {
				padding: 0;
			}
		}
	}

	.gt-carousel-nav {
		a {
			position: absolute;
			top: 50%;
			height: 48px;
			width: 28px;
			transform: translateY(-50%);
			.icon {
				height: 48px;
				width: 28px;
				@media(max-width: $screen-xs-max) {
					height: 27px;
					width: 16px;
				}
			}
			&#prev {
				left: 0;
				@media(max-width: $screen-xs-max) {
					left: 40px;
				}
				@media(max-width: $screen-xs-min) {
					left: 15px;
				}
			}
			&#next {
				right: 0;
				@media(max-width: $screen-md-min) {
					display: none !important;
				}
				@media(max-width: $screen-xs-max) {
					display: block !important;
					right: 40px;
				}
				@media(max-width: $screen-xs-min) {
					right: 15px;
				}
			}
			&:hover {
				border: unset;
			}
			svg {
				fill: #000;
			}
			@media(max-width: $screen-xs-max) {
				top: 62%;
				height: 27px;
				width: 16px;
			}
		}
	}
	@media(max-width: $screen-xs-max) {
		margin: 50px 0;
	}
}

/* ------------------------------------------------------------ *\
	#industry #Branchen
\* ------------------------------------------------------------ */
.gt-item-industry {
	padding-left: 25px;
	margin-bottom: 150px;
	.box-header {
		position: relative;
		margin-bottom: 100px;
		.heading{
			position: relative;
			color: $color-text;
			text-transform: uppercase;
			z-index: 1;
			.subheading {
				margin-top: 80px;
				margin-bottom: 25px;
				font-family: "Solido Constricted Black";
				font-size: 30px;
				line-height: 0.87;
				@media(max-width: $screen-md-max) {
					margin-top: 0;
					font-size: 24px;
				}
				@media(max-width: $screen-xs-max) {
					margin-bottom: 5px;
					font-size: 22px;
				}
			}
			.title {
				width: 471px;
				font-family: "Solido Condensed ExtraBold";
				font-size: 70px;
				line-height: 0.99;
				@media(max-width: $screen-md-max) {
					width: 310px;
					font-family: "Solido Compressed Bold";
					font-size: 55px;
				}
				@media(max-width: $screen-xs-max) {
					width: 305px;
					font-size: 37px;
					line-height: 0.97;
					letter-spacing: -0.8px;
				}
			}
			@media(max-width: $screen-xs-max) {
				width: 100%;
			}
		}
		.image-container {
			position: relative;
			.main-image {
				position: relative;
				width: 100%;
				z-index: 1;
			}
			@media(max-width: $screen-xs-max) {
				margin-bottom: 50px;
			}
			.background-square {
				position: absolute;

			}
			@media(max-width: $screen-md-max) {
				width: 57%;
			}
			@media(max-width: $screen-xs-max) {
				width: 100%;
			}
		}
		@media(max-width: $screen-xs-max) {
			margin-bottom: 20px;
		}
	}
	&:nth-of-type(1) {
		.image-container {
			width: 624px;
			height: 448px;
			@media(max-width: $screen-md-max) {
				width: 380px;
				height: 255px;
			}
			@media(max-width: $screen-md-min) {
				width: 330px;
				height: 235px;
			}
			@media(max-width: $screen-xs-max) {
				width: 300px;
				height: 205px;
			}
			@media(max-width: $screen-xs-min) {
				width: 260px;
				height: 185px;
			}
			.background-square {
				background-color: #fed0b8;
				top: 25px;
				left: 140px;
				width: 550px;
				height: 460px;
				@media(max-width: $screen-md-max) {
					top: 50px;
					width: 305px;
					height: 260px;
				}
				@media(max-width: $screen-md-min) {
					left: 95px;
					width: 275px;
					height: 230px;
				}
				@media(max-width: $screen-xs-max) {
					top: 20px;
					left: 65px;
					width: 275px;
					height: 215px;
				}
				@media(max-width: $screen-xs-min) {
					top: 10px;
					left: 75px;
					width: 215px;
					height: 185px;
				}
			}
		}
		.box-header {
			.heading {
				.title {
					width: 450px;
				}
			}
		}
		.copyright {
			color: #fff;
		}
	}
	&:nth-of-type(2) {
		.image-container {
			width: 609px;
			height: 435px;
			@media(max-width: $screen-md-max) {
				width: 380px;
				height: 255px;
			}
			@media(max-width: $screen-md-min) {
				width: 355px;
				height: 254px;
			}
			@media(max-width: $screen-xs-max) {
				width: 300px;
				height: 205px;
			}
			@media(max-width: $screen-xs-min) {
				width: 281px;
				height: 201px;
			}
			.background-square {
				background-color: #d9d9cf;				
				top: -45px;
				right: -15px;
				width: 525px;
				height: 370px;
				@media(max-width: $screen-md-max) {
					top: -30px;
					width: 305px;
					height: 260px;
				}
				@media(max-width: $screen-md-min) {
					width: 275px;
					height: 230px;
				}
				@media(max-width: $screen-xs-max) {
					width: 275px;
					height: 215px;
				}
				@media(max-width: $screen-xs-min) {
					top: -20px;
					left: 75px;
					width: 215px;
					height: 185px;
				}
			}
		}
	}
	&:nth-of-type(3) {
		.image-container {
			width: 574px;
			height: 409px;
			@media(max-width: $screen-md-max) {
				width: 380px;
				height: 255px;
			}
			@media(max-width: $screen-md-min) {
				width: 344px;
				height: 245px;
			}
			@media(max-width: $screen-xs-max) {
				width: 300px;
				height: 205px;
			}
			@media(max-width: $screen-xs-min) {
				width: 269px;
				height: 192px;
			}
			.background-square {
				background-color: #fff7b2;
				top: 65px;
				left: 110px;
				width: 491px;
				height: 400px;
				@media(max-width: $screen-md-max) {
					top: 50px;
					width: 305px;
					height: 260px;
				}
				@media(max-width: $screen-md-min) {
					left: 95px;
					width: 275px;
					height: 230px;
				}
				@media(max-width: $screen-xs-max) {
					top: 20px;
					left: 65px;
					width: 275px;
					height: 215px;
				}
				@media(max-width: $screen-xs-min) {
					top: 20px;
					left: 70px;
					width: 215px;
					height: 185px;
				}
			}
		}
	}
	&:nth-of-type(4) {
		.image-container {
			width: 613px;
			height: 438px;
			@media(max-width: $screen-md-max) {
				width: 380px;
				height: 255px;
			}
			@media(max-width: $screen-md-min) {
				width: 340px;
				height: 243px;
			}
			@media(max-width: $screen-xs-max) {
				width: 300px;
				height: 205px;
			}
			@media(max-width: $screen-xs-min) {
				width: 266px;
				height: 190px;
			}
			.background-square {
				background-color: #d0e2d9;
				top: -25px;
				right: 145px;
				width: 535px;
				height: 380px;
				@media(max-width: $screen-md-max) {
					top: -15px;
					width: 325px;
					height: 250px;
				}
				@media(max-width: $screen-md-min) {
					right: 45px;
					width: 310px;
					height: 235px;
				}
				@media(max-width: $screen-xs-max) {
					width: 275px;
					height: 215px;
				}
				@media(max-width: $screen-xs-min) {
					right: 75px;
					width: 215px;
					height: 185px;
				}
			}
		}
	}
	&:nth-of-type(5) {
		.image-container {
			width: 577px;
			height: 412px;
			@media(max-width: $screen-md-max) {
				width: 380px;
				height: 255px;
			}
			@media(max-width: $screen-md-min) {
				width: 344px;
				height: 240px;
			}
			@media(max-width: $screen-xs-max) {
				width: 300px;
				height: 205px;
			}
			@media(max-width: $screen-xs-min) {
				width: 269px;
				height: 188px;
			}
			.background-square {
				background-color: #ddeef9;
				top: 30px;
				left: 90px;
				width: 515px;
				height: 430px;
				@media(max-width: $screen-md-max) {
					top: 50px;
					width: 305px;
					height: 260px;
				}
				@media(max-width: $screen-md-min) {
					left: 95px;
					width: 275px;
					height: 230px;
				}
				@media(max-width: $screen-xs-max) {
					top: 20px;
					left: 65px;
					width: 275px;
					height: 215px;
				}
				@media(max-width: $screen-xs-min) {
					top: 10px;
					left: 90px;
					width: 192px;
					height: 205px;
				}
			}
		}
	}
	.box-content {
		.richtext {
			font-size: 28px;
			line-height: 1.36;
			&:nth-of-type(2) {
				padding-left: 30px;
				@media(max-width: $screen-md-max) {
					padding-top: 20px;
					padding-left: 15px;
				}
			}
			@media(max-width: $screen-md-max) {
				font-size: 24px;
			}
			@media(max-width: $screen-xs-max) {
				font-size: 20px;
			}
		}
	}
	&:nth-child(odd) {
		.heading {
			float: left;
			margin-left: 90px;
			@media(max-width: $screen-md-max) {
				margin-left: 50px;
			}
			@media(max-width: $screen-xs-max) {
				margin-left: 0;
			}
		}
		.image-container {
			float: left;
		}
	}
	&:nth-child(even) {
		.heading {
			float: right;
			margin-right: 90px;
			@media(max-width: $screen-md-max) {
				margin-right: 50px;
			}
			@media(max-width: $screen-xs-max) {
				margin-right: 0;
			}
		}
		.image-container {
			float: right;
			text-align: right;
		}
	}
	@media(max-width: $screen-md-max) {
		padding-right: 25px;
	}
	@media(max-width: $screen-xs-max) {
		padding: 0 15px;
	}
}


/* ------------------------------------------------------------ *\
	#map
\* ------------------------------------------------------------ */

.gt-marker {
	background-size: cover;
	width: 20px;
	border-radius: 50%;
	border-width: 0;
	border-color: transparent;
	border-style: solid;
	cursor: pointer;
	opacity: 0;
	visibility: visible;
	transition: opacity 0.4s;
	overflow: hidden;
	&.transport {
		background: $color-transport;
	}
	&.energy {
		background: $color-energy;
	}
	&.health {
		background: $color-health;
	}
	&.fashion {
		background: $color-fashion;
	}
	&.live {
		background: $color-live; 
	}
	&.has-flag {
		height: 20px;
		border-width: 3px;
		border-color: #000;
		opacity: 1;
		visibility: visible;
	}
}
 
.mapboxgl-popup {
	
	position: relative;
	max-height: 520px;
	max-width: 360px !important;
	box-sizing: border-box;
	.mapboxgl-popup-tip {
		display: none;
	}
	.mapboxgl-popup-content {
		padding: 0;
		button {
			display: none;
		}
		.content {
			padding: 30px;
			&.transport {
				background: $color-transport;
			}
			&.energy {
				background: $color-energy;
			}
			&.health {
				background: $color-health;
			}
			&.fashion {
				background: $color-fashion;
			}
			&.live {
				background: $color-live; 
			}
			.heading {
				.title {
					float: left;
					width: 90%;
					margin-top: 0;
					margin-bottom: 30px;
					font-family: "Solido Condensed ExtraBold";
					font-size: 33px;
					line-height: 0.86;
					text-transform: uppercase;
					@media (max-width: $screen-md-min){
						font-size: 28px;
						width: 160px;
						letter-spacing: 0.5px;
						line-height: 1;
					}
				}
				.icon {
					float: right;
					height: 18px;
					width: 18px;
					margin-left: auto;
					cursor: pointer;
					fill: #000;
				}
			}
			.subtitle {
				p {
					margin-top: 0;
					margin-bottom: 20px;
					font-family: "Solido Constricted Black";
					font-size: 21px;
					line-height: 0.97;
					text-transform: uppercase;
					@media (max-width: $screen-md-min){
						font-size: 18px;
						letter-spacing: -0.4px;
						line-height: 1.07;
					}
				}
			}
			.address {
				margin-bottom: 20px;
			}
			.description {
				font-size: 20px;
				line-height: 1.15;
				margin-bottom: 25px;
				@media (max-width: $screen-md-min){
					font-size: 18px;
					line-height: 1.17;
				}
			}
			@media (max-width: $screen-md-min){
				padding: 20px;
			}
			@media (max-width: $screen-xs-max){
				padding: 15px;
			}
		}
		img {
			@media (max-width: $screen-xs-max){
				display: none;
			}
		}
		.copyright {
			    position: absolute;
			    bottom: 10px;
			    right: 10px;
			    margin-bottom: 0;
			    font-family: "Solido Constricted Black";
			    font-size: 10px;
			    color: #fff;		
			    @media (max-width: $screen-xs-max){
					display: none;
				}
		}
		@media (max-width: $screen-md-min){
			max-height: 535px;
			max-width: 240px !important;
		}
	}
	&.mapboxgl-popup-anchor-top {
		top: -50%;
	}
	&.mapboxgl-popup-anchor-bottom {
		top: 3%;
	}
	@media (max-width: $screen-md-min){
		left: 6%;
	}
	@media (max-width: $screen-xs-max){
		left: 10%;
	}
	@media (max-width: $screen-xs-min){
		left: 14%;
	}
	@media (max-width: 361px){
		left: -40%;
	}
	&.address {
		max-width: 300px !important;
		.content {
			padding: 30px 30px 10px;
			.heading {
				.subtitle {
					margin-bottom: 10px;
				}
			}
			.title {
				margin-bottom: 20px;
			}
			img {
				max-width: 300px;
				max-height: 150px
			}
		}
	}
}
.map-container {
	#gt-map {
		height: 1100px;
		margin-bottom: 100px;
		overflow: hidden;
		@media (max-width: $screen-md-min){
			height: 640px;
		}
	}
}
.company-map-container {
	float: left;
	height: 1030px;
	width: 80%;
	#gt-map {
		height: 100%;
		overflow: hidden;
	}
	.mapboxgl-popup.mapboxgl-popup-anchor-top {
		top: -5%;
	}
	@media (max-width: $screen-md-max){
		width: 75%;
	}
	@media (max-width: $screen-md-min){
		height: 824px;
	}
	@media (max-width: $screen-xs-max){
		width: 100%;
		height: 824px;
	}
}

/* ------------------------------------------------------------ *\
	#accordion
\* ------------------------------------------------------------ */
.gt-accordion {
	overflow: hidden;
	.accordion-item {
		color: $color-text;
		padding: 30px;
		background-color: #fff;
		border-bottom: 1px solid #000;
		&:nth-of-type(1) {
			border-top: 1px solid #000;
		}
		.accordion-item-header {
			position: relative;
			h5 {
				float: left;
				width: 72%;
				font-family: "Solido Constricted Black";
				font-size: 28px;
				cursor: pointer;
				@media(max-width: $screen-md-max) {
					font-family: "Solido Condensed ExtraBold";
					font-size: 26px;
					line-height: 1.15;
					letter-spacing: -0.1px;
				}
				@media(max-width: $screen-xs-max) {
					font-size: 21px;
					line-height: 1.27;
				}
			}
			.toggle-text {
				float: right;
				margin-right: 20px;
				margin-left: auto;
				font-family: "Phosphate";
				font-size: 24px;
				letter-spacing: 1.41;
				cursor: pointer;
				@media(max-width: $screen-xs-max) {
					display: none;
				}
			}
			.icon {
				float: right;
				height: 30px;
				width: 30px;
				fill: #000;
				transition: 0.4s all;
				cursor: pointer;
			}			
			@media (max-width: $screen-sm-max) {
				h5 {
					font-size: 26px;
					line-height: 30px;
					letter-spacing: -.1px;
				}
			}
			@media (max-width: $screen-xs-max) {
				h5 {
					font-size: 22px;
					line-height: 28px;
					letter-spacing: 0px;
					width: 85%;
				}
			}
		}
		.accordion-item-body {
			max-height: 0;
			overflow: hidden;
			transition: 0.5s all;
			.richtext {
				margin-top: 20px;
				p {
					margin-bottom: 40px;
					font-size: 28px;
					@media(max-width: $screen-md-max) {
						font-size: 26px;
						line-height: 1.25;
					}
					@media(max-width: $screen-xs-max) {
						font-size: 20px;
						line-height: 1.3;
					}
				}
				h5 {
					margin-bottom: 20px;
					font-family: "Solido Constricted Black";
					font-size: 28px;
				}				
				@media (max-width: $screen-sm-max) {
					h5 {
						font-size: 26px;
						line-height: 30px;
						letter-spacing: -.1px;
					}
					p {
						font-size: 24px;
						line-height: 30px;
						letter-spacing: 0px;
					}
				}
				@media (max-width: $screen-xs-max) {
					h5 {
						font-size: 22px;
						line-height: 28px;
						letter-spacing: 0px;
					}
					p {
						font-size: 20px;
						line-height: 26px;
						letter-spacing: 0px;
					}
				}

			}
		}
		&.show {
			background-color: #f2f2f2;
			.accordion-item-body {
				max-height: 300px;
			}
			.icon {
				transform: rotate(180deg);
			}			
		}
		@media(max-width: $screen-md-max) {
			padding: 20px;
		}
		@media(max-width: $screen-xs-max) {
			padding: 15px;
		}
	}
	&.gt-accordion-courses {
		.accordion-item {
			.accordion-item-header {
				font-size: 28px;
				h5 {					
					float: left;
					width: 32%;
					@media(max-width: $screen-md-max) {
						width: 35%;
						line-height: 1.15;
					}
					@media(max-width: $screen-xs-max) {
						margin-bottom: 20px;
					}
				}
				.course-info {
					float: left;
					width: 64%;
					.course-type,
					.course-duration {
						float: left;
						width: 50%;
						@media(max-width: $screen-md-max) {
							width: 100%;
							font-size: 24px;
							line-height: 1.25;
						}
						@media(max-width: $screen-md-min) {
							float: unset;
							width: 100%;
							font-size: 24px;
						}
						@media(max-width: $screen-xs-max) {
							font-size: 20px;
						}
					}
					@media(max-width: $screen-md-max) {
						width: 50%;
						padding-left: 20px;
					}
					@media(max-width: $screen-xs-max) {
						padding-left: 0;
					}
				}
				.icon {
					float: right;
				}
				@media (max-width: $screen-xs-max) {
					h5 {
						width: 80%;
					}
					.course-type,
					.course-duration {
						width: 80%;
						float: none;
					}
				}
			}
			&.show {
				.accordion-item-body {
					max-height: 1200px;
					.richtext {
						margin-top: 50px;
					}
				}
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	#sidebar
\* ------------------------------------------------------------ */
.gt-map-sidebar {
	float: left;
	width: 20%;
	margin: 0;
	li {
		border-bottom: 1px solid #000;
		border-left: 1px solid #000;
		&:first-child {
			border-top: 1px solid #000;
		}
		a {
			display: block;
			padding: 25px 70px;
			font-family: "Solido Condensed ExtraBold";
			font-size: 34px;
			text-align: center;
			text-transform: uppercase;
			word-break: break-word;
			line-height: 1.17;
			@media(max-width: 1440px) {
				font-size: 30px;
			}
			@media(max-width: $screen-md-max) {
				padding: 20px;
				font-size: 28px;
				letter-spacing: -0.2px;
				line-height: 1.14;
			}
			@media(max-width: $screen-xs-max) {
				font-size: 26px;
			}
		}
		&.has-flag,
		&:hover {
			background: #000;
			a {
				color: #fff;
			}
		}
		@media(max-width: 1440px) {
			border-left: unset;
		}
	}
	@media(max-width: $screen-md-max) {
		width: 25%;
	}
	@media (max-width: $screen-xs-max){
		width: 100%;
	}
}
/* ------------------------------------------------------------ *\
	#companies
\* ------------------------------------------------------------ */

.gt-filter-companies {
	border-bottom: 1px solid #000;
	margin-bottom: 100px;
	padding-top: 50px;
	li {
		display: inline-block;
		padding: 0 10px;
		border-bottom: 5px solid transparent;
		a {
			font-family: "Solido Compressed Bold";
			font-size: 30px;
			text-transform: uppercase;
			@media(max-width: $screen-md-max) {
				font-size: 26px;
				letter-spacing: -0.5px;
				line-height: 1;
			}
			@media(max-width: $screen-md-min) {
				font-size: 21px;
			}
			@media(max-width: $screen-xs-max) {
				font-size: 30px;
			}
			@media(max-width: $screen-xs-min) {
				width: 240px;
			}
		}

		&.has-flag,
		&:hover {
			border-bottom: 5px solid #000;
			background: #f2f2f2;
		}
		@media(max-width: $screen-md-max) {
			padding: 0 6px;
		}
		@media(max-width: $screen-xs-max) {
			margin-left: 20px;
			padding: 5px;
		}
	}
}

.gt-companies-list {
	text-align: left;
	margin-bottom: 40px;
	.gt-companies-item {
		padding: 0;
		border-bottom: 1px solid #000;
		height: 0;
		visibility: hidden;
		opacity: 0;
		overflow: hidden;
		transition: all 0.4s;
		&:first-child {
			border-top: 1px solid #000;
		}
		.title {
			float: left;
			width: 32%;
			font-family: "Solido Constricted Black";
			font-size: 28px;
			@media(max-width: $screen-md-max) {
				width: 70%;
				margin-bottom: 20px;
				font-size: 26px;
			}
			@media(max-width: $screen-xs-max) {
				float: unset;
				width: 100%;
				margin-bottom: 15px;
				font-size: 24px;
			}
		}
		.description {
			float: left;
			margin-left: 20px;
			font-size: 28px;
			@media(max-width: $screen-md-max) {
				width: 70%;
				margin-left: 0;
				font-size: 24px;
			}
			@media(max-width: $screen-xs-max) {
				float: unset;
				width: 100%;
				margin-bottom: 20px;
				font-size: 20px;
			}
		}
		.link {
			float: right;
			text-transform: uppercase;
			@media(max-width: $screen-xs-max) {
				float: unset;
			}
		}
		&.has-flag {
			height: auto;
			padding: 30px;
			visibility: visible;
			opacity: 1;
			@media(max-width: $screen-xs-max) {
				padding: 15px;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	#company details
\* ------------------------------------------------------------ */

.map-container-company-details {
	height: 380px;
	#gt-map {
		height: 100%;
		overflow: hidden;
	}
	@media(max-width: $screen-md-max) {
		float: right;
		width: 49%;
		height: 450px;
	}
	@media(max-width: $screen-xs-max) {
		float: unset;
		width: 100%;
		height: 360px;
	}
}

/* ------------------------------------------------------------ *\
	#gallery
\* ------------------------------------------------------------ */
.gt-gallery {
	img {
		width: 282px;
		height: 227px;
		object-fit: cover;
		font-family: 'object-fit: cover;';
		@media(max-width: $screen-md-max) {
			width: 344px;
			height: 276px;
			margin-bottom: 20px;
		}	
		@media (max-width: $screen-xs-max) {
			width: 288px;
			height: 231px;
		}
	}

	@media (max-width: $screen-xs-max) {
		text-align: center;
		& >div {
			float: unset;
			width: 100%;
			padding: 0;
		}
	}
}

/* ------------------------------------------------------------ *\
	#gallery
\* ------------------------------------------------------------ */
.gt-parents-teachers-carousel {
	position: relative;
	margin: 150px 0;
	.gt-carousel-item {
		position: relative;
		.background-square {
			position: absolute;
			top: 20px;
			left: 25%;
			width: 475px;
			height: 385px;
			background: #fed0b8;
			@media(max-width: $screen-md-max) {
				left: 25%;
				width: 300px;
				height: 250px;
			}
			@media(max-width: $screen-xs-max) {
				left: 40%;
				width: 270px;
				height: 260px;
			}
			@media(max-width: $screen-xs-min) {
				left: 34px;
				width: 254px;
				height: 179px;
			}
		}
		.content {
			padding: 0 70px;
			.description {
				float: left;
				position: relative;
				margin-top: 50px;
				padding-left: 50px;
				color: $color-text;
				text-align: right;
				text-transform: uppercase;
				z-index: 10;
				.subheading {
					margin-bottom: 30px;
					font-family: "Solido Constricted Black";
					font-size: 30px;
					text-align: left;
					line-height: 0.87;
					letter-spacing: 0.5px;
					@media(max-width: $screen-md-max) {
						margin-bottom: 20px;
						font-size: 22px;
					}
					@media(max-width: $screen-xs-max) {
						margin-bottom: 15px;
						font-size: 16px;
					}
					@media(max-width: $screen-xs-min) {
						text-align: center;
					}
					@media(max-width: 350px) {
						text-align: left;
					}
				}
				.title {
					width: 480px;
					margin-bottom: 52px;
					font-family: "Solido Compressed Bold";
					font-size: 77px;
					text-align: left;
					line-height: 0.99;
					letter-spacing: -1px;
					@media(max-width: $screen-md-max) {
						width: 320px;
						margin-bottom: 20px;
						font-size: 57px;
					}
					@media(max-width: $screen-xs-max) {
						width: 405px;
						margin-bottom: 15px;
						font-size: 37px;
					}
					@media(max-width: $screen-xs-min) {
						width: 180px;
						margin: 0 auto 15px;
						font-size: 37px;
					}
					@media(max-width: 350px) {
						margin: 0 0 15px;
					}
				}
				.link {
					text-align: right;
					@media(max-width: $screen-xs-max) {
						float: left;
					}
					@media(max-width: $screen-xs-min) {
						float: unset;
					}
				}
				@media(max-width: $screen-md-max) {
					margin-top: 30px;
					padding-left: 40px;
				}
				@media(max-width: $screen-md-min) {
					width: auto;
				}
				@media(max-width: $screen-xs-max) {
					float: unset;
					padding: 0;
					width: 100%;
				}
				@media(max-width: $screen-xs-min) {
					text-align: center;
				}
				@media(max-width: 350px) {
					text-align: left;
				}
			}
			.image-container {
				float: left;
				width: 525px;
				height: 375px;
				position: relative;
				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
					font-family: 'object-fit: cover;';
					position: relative;
					z-index: 1;
				}
				@media(max-width: $screen-md-max) {
					width: 380px;
					height: 260px;
				}
				@media(max-width: $screen-md-min) {
					width: 329px;
					height: 235px;
				}
				@media(max-width: $screen-xs-max) {
					float: unset;
					width: 380px;
					height: 260px;
					margin: 0 auto;
					padding: 0;
				}
				@media(max-width: $screen-xs-min) {
					width: 253px;
					height: 180px;
					margin: 0 auto;
				}
				@media(max-width:350px) {
					margin: 0;
				}
			}
			@media(max-width: $screen-md-max) {
				padding: 0;
			}
		}

		&.transport {
			.background-square {
				background-color: #fed0b8;
			}
			.content {
				.description {
					.title {
						width: 460px;
						margin-bottom: 55px;
						@media(max-width: $screen-md-max) {
							width: 320px;
							margin-bottom: 10px;
						}
						@media(max-width: $screen-xs-max) {
							width: auto;
							margin-bottom: 10px;
						}
					}
				}
			}
		}
		&.energy {
			.background-square {
				background-color: #d9d9cf;
				top: -10%;
				left: unset;
				right: -3%;
				height: 338px;
				@media (max-width: $screen-md-max){
					width: 380px;
					height: 280px;
				}
				@media (max-width: $screen-md-min){
					top: -15px;
					right: 145px;
					width: 305px;
					height: 230px;
				}
				@media (max-width: $screen-xs-max){
					width: 350px;
					height: 260px;
					right: 70%;
					transform: translateX(50%);
				}
				@media (max-width: $screen-xs-min){
					width: 200px;
					height: 170px;
					right: 90px;
					transform: none;
				}
			}
			.copyright {
				color: #fff;
			}
			.content {
				.description {
					float: right;
					padding-left: 0;
					padding-right: 50px;
					.title {
						margin-bottom: 60px;
						@media(max-width: $screen-xs-max) {
							margin-bottom: 10px;
						}
					}
					@media(max-width: $screen-md-max) {
						padding-right: 20px;
					}
				}
			}
			.image-container {
				float: right;
			    margin-top: 40px;
			}
		}
		&.health {
			.background-square {
				background-color: #fff7b2;
			    top: 10%;
			    left: 14%;
				@media (max-width: $screen-xs-max){
					width: 350px;
					height: 260px;
				}
				@media (max-width: $screen-xs-min){
					width: 254px;
					height: 170px;
				}
			}
			.content {
				.description {
					.title {
						margin-bottom: 65px;
						@media(max-width: $screen-md-max) {
							margin-bottom: 10px;
						}
					}
				}
			}
		}
		&.fashion {
			.background-square {
				background-color: #d0e2d9;
				top: -23px;
				left: unset;
				right: 110px;
				height: 338px;
				@media (max-width: $screen-md-max){
					width: 380px;
					height: 280px;
				}
				@media (max-width: $screen-md-min){
					top: -15px;
					right: 145px;
					width: 305px;
					height: 230px;
				}
				@media (max-width: $screen-xs-max){
					width: 350px;
					height: 260px;
					right: 70%;
					transform: translateX(50%);
				}
				@media (max-width: $screen-xs-min){
					width: 200px;
					height: 170px;
					right: 90px;
					transform: none;
				}
			}
			.copyright {
				color: #fff;
			}
			.content {
				.description {
					float: right;
					padding-left: 0;
					padding-right: 50px;
					.title {
						margin-bottom: 40px;
						@media(max-width: $screen-md-max) {
							margin-bottom: 10px;
						}
					}
					@media(max-width: $screen-md-max) {
						padding-right: 20px;
					}
				}
			}
			.image-container {
				float: right;
			    margin-top: 35px;
			}
		}
		&.live {
			.background-square {
			    top: 10%;
			    left: 14%;
				background-color: #ddeef9;
				@media (max-width: $screen-xs-max){
					width: 350px;
					height: 260px;
				}
				@media (max-width: $screen-xs-min){
					width: 254px;
					height: 170px;
				}
			}
			.content {
				.description {
					.title {
						margin-bottom: 65px;
						@media(max-width: $screen-md-max) {
							margin-bottom: 10px;
							width: 350px;
						}
						@media(max-width: $screen-xs-max) {
							width: auto;
						}
					}
					@media(max-width: $screen-md-max) {
						padding-left: 20px;
					}
					@media(max-width: $screen-xs-max) {
						padding-left: 0;
					}
				}
			}
		}

	}

	.gt-carousel-nav {
		a {
			position: absolute;
			top: 50%;
			&#prev {
				left: 0;
			}
			&#next {
				right: 0;
			}
			&:hover {
				border: unset;
			}
			svg {
				height: 48px;
				width: 28px;
				fill: #000;
				@media(max-width: $screen-md-max) {
					height: 39px;
					width: 23px;
				}
				@media(max-width: $screen-xs-max) {
				}
			}
			@media(max-width: $screen-md-max) {
				top: 45%;
			}
			@media(max-width: $screen-xs-max) {
				top: 35%;
			}
			@media(max-width: $screen-xs-min) {
				top: 15%;
			}
		}
	}
	@media(max-width: $screen-xs-max) {
		margin: 0;
	}
}


/* ------------------------------------------------------------ *\
	#cookies #popup
\* ------------------------------------------------------------ */
.gt-overlay {
	display: none;
	position: fixed;
	background: rgba(0, 0, 0, 0.5);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
}
.gt-cookie-popup {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;		
	transform: translate(-50%, -50%);
	z-index: 1010;
	.cookie-container {
		position: relative;
		width: 380px;
		padding: 20px;
		background: #fff;
		.heading {
			margin-bottom: 20px;
			font-family: "Solido Condensed ExtraBold";
			font-size: 34px;
			line-height: 1.18;
			letter-spacing: -0.25px;
			@media(max-width: $screen-xs-max) {
				font-size: 28px;
				line-height: 1.11;
				letter-spacing: -0.75px;
			}
		}
		p {
			margin-bottom: 35px;
			font-size: 18px;
			line-height: 1.22;
			@media(max-width: $screen-xs-max) {
				font-size: 14px;
				line-height: 1.29;
			}
		}
		.gt-btn {
			margin-bottom: 30px;
			@media(max-width: $screen-xs-max) {
				width: 175px;
				font-size: 28px;
				text-align: center;
			}
		}
		.link {
			display: block;
			position: relative;
			width: 170px;
			margin-bottom: 25px;
			&:after {
				position: absolute;
				bottom: 0;
				right: -40px;
				@media(max-width: $screen-xs-max) {
					right: -10px;
				}
			}
		}
		.cookie-heading {
			input {
				height: 0;
				visibility: hidden;
				opacity: 0;
				&:checked {
					&+.gt-checkbox {
						&:before {
							border: 1px solid #000;
						}
						&:after {
							border-color: #000;
						}
					}
				}
				&:disabled {
					&+.gt-checkbox {
						&:before {
							border: 1px solid #d2d2d2;
						}
						&:after {
							border-color: #d2d2d2;
						}
					}
				}
			}
			.gt-checkbox {
				display: block;
				position: relative;
				float: left;
				width: 80%;
				margin-top: 5px;
				margin-bottom: 15px;
				font-family: "Solido Bold";
				font-size: 18px;
				line-height: 1.22;
				&:before {
					content: '';
					position: absolute;
					right: -25%;
					width: 25px;
					height: 25px;
					border: 1px solid #000;
					transition: 0.4s all;
				}
				&:after {
					content: '';
					position: absolute;
					right: -22%;
					top: 3px;
					width: 8px;
					height: 15px;
					border: solid transparent;
	  				border-width: 0 3px 3px 0;
	  				transform: rotate(45deg);
					transition: 0.4s all;
				}
			}
		}
		.cookie-footer {
			font-size: 12px;
			line-height: 1.25;
			letter-spacing: -0.15px;
			a {
				margin-right: 50px;
				@media(max-width: $screen-xs-max) {
					margin-right: 30px;
				}
			}
		}
		.cookie-settings {
			height: 0;
			visibility: hidden;
			opacity: 0;
			overflow: hidden;
			transition: 0.4s all;
		}
		&.has-flag {
			.cookie-settings {
				height: auto;
				visibility: visible;
				opacity: 1;
			}
			.index {
				height: 0;
				visibility: hidden;
				opacity: 0;
			}
		}
		@media(max-width: $screen-xs-max) {
			width: 285px;
			padding: 15px;
			.cookie-heading {
				.gt-checkbox {
					&:before {
						right: -25%;
					}
					&:after {
						right: -21%;
					}
				}
			}
		}
	}
	&.has-flag {
		display: block;
		~ .gt-overlay{
			display: block;
		}
	}
}

.gt-socials {
	position: absolute;
	top: 30px;
	right: 0;
	font-size: 0;
	.icon {
		width: 50px;
		height: 50px;
		margin-bottom: 10px;
		&:first-child {
			margin-right: 10px;
		}
		@media (max-width: $screen-md-min){
			width: 40px;
			height: 40px;
			margin-bottom: 5px;
		}
		@media (max-width: $screen-xs-max){
			width: 30px;
			height: 30px;
		}
	}
	p {
		color: #fff;
		font-size: 10px;
		text-align: right;
	}
	@media (max-width: $screen-md-max){
		right: 30px;
	}
	@media (max-width: $screen-xs-max){
		bottom: 0;
		right: 5px;
	}
}

.gt-copyright {
	position: absolute;
	bottom: 5px;
	right: -480px;
	font-size: 0;
	.gt-helper-container {
		text-align: right;	
		a {
			display: inline-block;
			width: 50px;
			height: 50px;
			&:first-child {
				margin-right: 10px;
			}
			.icon {
				width: 50px;
				height: 50px;
				margin-bottom: 10px;
				@media (max-width: $screen-md-min){
					width: 40px;
					height: 40px;
					margin-bottom: 5px;
				}
				@media (max-width: $screen-xs-max){
					width: 30px;
					height: 30px;
				}
			}
			@media (max-width: $screen-md-min){
				width: 40px;
				height: 40px;
			}
			@media (max-width: $screen-xs-max){
				width: 30px;
				height: 30px;
			}
		}
		p {
			color: #fff;
			font-size: 10px;
			text-align: right;
		}
	}
	&.gt-alter-style {
		right: 0;
		@media (max-width: $screen-md-max){
			right: 30px;
		}
		@media (max-width: $screen-xs-max){
			bottom: 0;
		}
	}
	@media (max-width: $screen-md-max){
		right: -380px;
	}
	@media (max-width: $screen-md-min){
		right: -280px;
	}
	@media (max-width: $screen-xs-max){
		right: 30px;
	}
}


.copyright {
	position: absolute;
	bottom: 10px;
	right: 10px;
	font-family: "Solido Condensed ExtraBold";
	font-size: 10px;
	color: #000;
	text-transform: none;
	z-index: 2;
}