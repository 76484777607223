input {
	border: 1px solid gray;
}
input:focus {
	outline: none;
	border-bottom-color: $color-primary;
	color: $color-primary;
	&::placeholder {
		color: $color-primary;
	}
	&::-moz-placeholder {
		color: $color-primary;
	}
	&::-webkit-placeholder {
		color: $color-primary;
	}
	&:-ms-input-placeholder {
		color: $color-primary;
	}
}
// input::-webkit-outer-spin-button /* field spin arrows */
// input::-webkit-inner-spin-button /* field spin arrows */
input::-webkit-clear-button { /* Removes blue cross */
  -webkit-appearance: none;
  margin: 0;
}
input[name*='captcha'] {
	padding: 0.375rem 0.75rem;
}

.wr-field {
	// &[type="text"],
	// &[type="number"],
	// &[type="password"],
	// &[type="email"],
	// &[type="date"],
	// &[type="time"],
	// &[type="day"],
	// &[type="week"],
	// &[type="month"],
	// &[type="year"],
	// &[type="search"],
	// &[type="tel"],
	// &[type="url"] {
		&:not([type="radio"]),
		&:not([type="checkbox"]) {
		font-size: 1.335em;
		font-weight: 500;
		line-height: 1.4;
		border-radius: 0;
		background: #ffffff;
		box-shadow: none;
		border-style: solid;
		border-width: 0 0 1px 0;
		border-color: gray;
		transition: .3s all;

		&::placeholder {
			color: gray;
			opacity: 0.8; 
		}
		&:-ms-input-placeholder {
			color: gray;
			opacity: 0.8;
		}
		&::-ms-input-placeholder {
			color: gray;
			opacity: 0.8;
		}

		&.is-invalid {
			border-color: $color-error;
			color: $color-error;
			&:focus {
				border-color: $color-error;
				color: gray;
			}
			&::placeholder {
				color: $color-error;
				opacity: 1; 
			}
			&:-ms-input-placeholder {
				color: $color-error;
				opacity: 1;
			}
			&::-ms-input-placeholder {
				color: $color-error;
				opacity: 1;
			}
		}
		&.is-valid {
			border-color: $color-success;
		}
		&:focus {
			box-shadow: none;
			border-color: $color-primary;
			&::placeholder {
				color: $color-primary;
			}
			&::-moz-placeholder {
				color: $color-primary;
			}
			&::-webkit-placeholder {
				color: $color-primary;
			}
			&:-ms-input-placeholder {
				color: $color-primary;
			}
		}

	}
}
.wr-form-check {
	padding-left: 2em;
	.wr-check-label {
		position: relative;
		font-size: 10px;
		&:before,
		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0; 
			width: 1.25rem;
			height: 1.25rem;
			margin-left: -2rem;
			text-align: center;
			line-height: 1.25rem;
		}
		&:before {
			border: 1px solid gray;
			background: #ffffff;
		}
	}
	.wr-check {
		width: 1.25em;
		height: 1.25em; 
		margin-left: -2em;
		margin-top: 0;
		opacity: 0;
		&:checked + .wr-check-label:after {
			content: '';
			font-size: 1em;
		}
		&.is-invalid + .wr-check-label:before {
			border-color: $color-error;
		}
	}
}
.wr-datepicker {
	position: relative;
	input {
		padding: 5px 15px;
		position: relative;
		text-overflow: ellipsis;
		&.is-invalid {
			border-color: $color-error;
			color: $color-error;
		}
		&.is-invalid + .invalid-feedback {
			display: block;
		}
	}
	.datepicker {
		&.datepicker-dropdown {
			width: 100%;
			border-radius: 0;
			&:before,
			&:after {
				content: none;
			}
			.datepicker-days {
				.dow {
					color: #848484;
					font-size: 10px;
					font-weight: 500;
					line-height: 15px;
				}
				.day {
					padding: 5px 0;
					font-size: 16px; 

					&:hover {
						background: none;
						color: $color-primary;
					}
				}
				.new,
				.old {
					font-size: 0;
					line-height: 0;
					padding: 0;
					height: 0;
				}
			}
			table.table-condensed {
				width: 100%;
			}
		}
	}
}
