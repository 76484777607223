@media print {
	* {
		margin: 0px !important;
		padding: 0 !important; 
	}
	.wr-content-page {
		display: block !important;
		margin: 0px !important;
		padding: 0 !important; 
	}
	.wr-main {
		max-height: 100vh !important;
	}
}