/* ======================= #buttons ======================= */
.gt-btn {
	position: relative;
	&.gt-btn-primary {
		display: inline-block;
		padding: 10px;
		color: #fff;
		background: #000;
		font-family: "Solido Condensed ExtraBold";
		font-size: 30px;
		line-height: 1;
	}
}
.link.gt-element-mehr{
	vertical-align: bottom;
	&::after{
		clear:both;
		background-size: 30px 30px;
		height: 30px;
		width: 30px;
	}
}
.gt-artikel-btn{
	width: fit-content;
	margin: 0 auto;
	@media(max-width: 767px){
		margin-bottom: 70px;
	}
	&.gt-btn-no-more{
		display: none;
	}
}
.gt-btn-alle{
	color:white;
	background-color: black;
	display: inline-block;
	padding: 10px;
	font-family: "Solido Condensed ExtraBold";
	font-size: 30px;
	line-height: 1;
	height: fit-content;
	&:hover, &:focus{
		color:white;
		text-decoration: none;
		outline: none;
	}
	@media(max-width: 992px){
		font-size: 25px;
		padding: 8px 10px;
	}
	@media(max-width: 676px){
		font-size: 20px;
		padding: 5px 10px;
	}
}
.gt-btns-under{
	.gt-socials{
		.icon{
			margin-bottom: 0;
		}
	}
}