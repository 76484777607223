.gt-navigation {
	width: 100%;
	padding: 35px 0;
	.navbar-default {
		margin: 0;
		border: 0;
		background: none;
	}
	.navbar {
		.gt-navbar-header {
			float: left;
			.logo {
				display: block;
				.icon {
					width: 270px;
					height: 65px;
					fill: #fff;
					@media (max-width: $screen-xs-max){
						width: 140px;
						height: 35px;
					}
				}
			}
			&.purple {
				.icon {
					fill: #dbd2ed;
				}
			}
			&.transport {
				.icon {
					fill: $color-transport;
				}
			}
			&.energy {
				.icon {
					fill: $color-energy;
				}
			}
			&.health {
				.icon {
					fill: $color-health;
				}
			}
			&.fashion {
				.icon {
					fill: $color-fashion;
				}
			}
			&.live {
				.icon {
					fill: $color-live; 
				}
			}
		}
		.gt-nav-drawer {
			float: right;
			.drawer {
				position: absolute;
				visibility: hidden;
				max-height: 0;
				opacity: 0;
				top: -35px;
				right: -120px;
				padding-top: calc(100% / 5);
				text-align: left;
				background: #fff;
				transition: opacity 0.4s;
				.drawer-content {
					padding: 0 120px 0 100px;
					font-family: "Solido Condensed ExtraBold";
					line-height: 1;
					text-transform: uppercase;
					a {
						border-bottom: 2px solid transparent;
						&:hover {
							border-bottom: 2px solid #000;
						}
					}
					.nav-primary {
						box-sizing: border-box;
						padding-right: 49px;
						li {
							margin-bottom: 60px;
							a {
								font-size: 51px;
								@media (max-width: $screen-md-max){
									font-size: 36px;
								}
								@media (max-width: $screen-md-max){
									font-size: 21px;
									line-height: 1;
									letter-spacing: -0.75px;
								}
							}
							@media (max-width: $screen-md-max){
								margin-bottom: 30px;
							}
							@media (max-width: $screen-xs-max){
								margin-bottom: 20px;
							}
						}
						@media (max-width: $screen-md-min){
							padding-right: 0;
						}
						@media (max-width: $screen-xs-max){
							margin-bottom: 30px;
						}
					}
					.nav-secondary {
						box-sizing: border-box;
						padding-left: 80px;
						li {
							margin-bottom: 40px;
							a {
								font-size: 28px;
								@media (max-width: $screen-md-max){
									font-size: 21px;
								}
								@media (max-width: $screen-md-max){
									font-size: 16px;
									line-height: 1;
									letter-spacing: -0.75px;
								}
							}
							@media (max-width: $screen-md-max){
								margin-bottom: 20px;
							}
						}					
						@media (max-width: $screen-md-max){
							padding-left: 40px;
						}
						@media (max-width: $screen-md-min){
							padding-left: 0;
						}
					}
					@media (max-width: $screen-md-max){
						margin-bottom: 150px;
						padding: 0 30px;
					}
					@media (max-width: $screen-xs-max){
						margin-bottom: 20px;
					}
				}
				.drawer-footer {
					padding: 60px 120px 40px 100px;
					background: #000;
					.social-links {
						li {
							display: inline-block;
							padding-right: 25px;
							a {
								display: block;
								.icon-svg {
									height: 40px;
									width: 40px;
									fill: #fff;
									@media (max-width: $screen-md-max){
										width: 30px;
										height: 30px;
									}
									@media (max-width: $screen-xs-max){
										width: 25px;
										height: 25px;
									}
								}
							}
						}
					}
					@media (max-width: $screen-md-max){
						padding: 60px 50px 40px;
					}
					@media (max-width: $screen-xs-max){
						padding: 25px 30px;
					}
				}
				@media (max-width: $screen-md-max){
					padding-top: 150px;
					right: -35px;
				}
				@media (max-width: $screen-md-max){
					padding-top: 100px;
				}
			}
			.nav-lang {
				float: right;
				margin-right: 50px;
				margin-top: 10px;
				li {
					float: left;
					width: 50px;
					height: 50px;
					box-sizing: border-box;
					margin-left: 10px;
					a {
						padding: 8px 10px;
						font-family: "Solido Condensed ExtraBold";
						color: inherit;
						background: inherit;
						font-size: 26px;
						@media (max-width: $screen-xs-max){
							padding: 8px;
							font-size: 20px;
						}
					}
					&:nth-child(1) {
						a {
							color: #fff;
							background: #000;
						}
					}
					&:nth-child(2) {
						a {
							color: #000;
							background: #fff;
						}
					}
					@media (max-width: $screen-xs-max){
						width: 35px;
						height: 35px;
						padding-top: 5px;
					}
				}
				@media (max-width: $screen-xs-max){
					height: 0;
					float: unset;
					margin-top: 0;
					margin-right: 60px;
					position: relative;
					visibility: hidden;
					overflow: hidden;
					opacity: 0;
					transition: 0.4s all;
					z-index: 1;
				}
			}

			.gt-menu-toggle {
				float: right;
				position: relative;
				z-index: 15;
				opacity: 1;
				transition: all 0.4s;
				& > div {
					position: relative;
					width: 50px;
					height: 50px;
					pointer-events: click;
					span {
						position: absolute;
						left: 0;
						bottom: 10px;
						width: 80%; 
						height: 3px;
						background: #000;
						transition: .3s all;
						@media (max-width: $screen-xs-max){
							height: 2px;
							bottom: 7px;
						}
						&:first-child {
							bottom: auto;
							top: 10px;
							@media (max-width: $screen-xs-max){
								top: 7px;
							}
						}
						&:nth-child(2) {
							top: 50%;
							width: 60%;
							transform: translateY(-50%);
							bottom: auto;
						}
					}
					@media (max-width: $screen-xs-max){
						width: 35px;
						height: 35px;
					}
				}
				&:hover {
					border-bottom: unset;
				}
				&.has-flag {
					opacity: 0;
				}
			}
			&.menu-open {
				.drawer {
					max-height: 100vh;
					visibility: visible;
					opacity: 1;
					transition: opacity 0.4s;
					overflow-y: scroll;
					&::-webkit-scrollbar {
						display: none;
					}
					@media(max-width: $screen-xs-max) {
    					max-height: calc(100vh + 25px);
					}
				}
				.gt-menu-toggle {
					& > div {
						span {
							&:first-child {
								transform: rotate(45deg);
								top: 22px;
								@media (max-width: $screen-xs-max){
									top: 15px;
								}
							}
							&:nth-child(2) {
								display: none;
							}
							&:last-child {
								transform: rotate(-45deg);
								bottom: 25px;
								@media (max-width: $screen-xs-max){
									bottom: 18px;
								}
							}
						}
					}
				}
				@media(max-width: $screen-xs-max) {
					.nav-lang {
						height: auto;
						visibility: visible;
						opacity: 1;
						transition: 0.4s all;
					}
				}
			}
		}
	}
	@media (max-width: $screen-lg){
		padding: 35px;
	}
	@media (max-width: $screen-xs-max){
		margin: 0;
		padding: 15px;
	}
}