.gt-magazin-artikel{
	display: flex;
	flex-wrap: wrap;
	align-items: end;
	.gt-artikel-element{
		flex-basis: 32%;
		margin-bottom: 70px;
		margin-right: 24px;
		.gt-artikel-element-image{
			margin-bottom: 54px;
			.gt-artikel-image{
				margin: 0 18.5px;
			}
		}
		&:nth-child(6n+1){
			.gt-artikel-image{
				margin: 0;
				box-shadow: 11px 10px #dbd2ed;
			}
		}
		&:nth-child(6n+2){
			.gt-artikel-image{
				margin: 0;
				box-shadow:  10px -26px #dbd2ed;
			}
		}
		&:nth-child(6n+3){
			.gt-artikel-image{
				box-shadow: -10px 34px #dbd2ed, 19px 34px #dbd2ed;
			}
		}
		&:nth-child(6n+4){
			.gt-artikel-image{
				margin: 0;
				box-shadow:  10px -26px #dbd2ed;
			}
		}
		&:nth-child(6n+5){
			.gt-artikel-image{
				margin: 0;
				box-shadow: 11px 10px #dbd2ed;
			}
		}
		&:nth-child(6n+6){
			.gt-artikel-image{
				box-shadow: -10px 34px #dbd2ed, 19px 34px #dbd2ed;
			}
		}
		&:nth-child(3n){
			margin-right: 0;
		}
		.gt-artikel-element-text{
			h5{
  				font-family: "Solido Condensed ExtraBold";
  				font-size: 28px;
  				font-weight: 800;
  				font-stretch: condensed;
  				line-height: 1.07;
  				letter-spacing: -0.2px;
  				color: var(--black);
  				margin-bottom: 10px;
			}
			p{
				font-family: "Solido Book";
  				font-size: 24px;
  				line-height: 1.3;
  				color: var(--black);
  				margin-bottom: 30px;
			}
		}
		@media(max-width: 1199px){
			margin-right: 24px;
			&:nth-child(3n){
				margin-right: 24px;
			}
			&:nth-child(2n){
				margin-right: 0;
			}
		}
		@media(max-width: 792px){
			margin-right: 0;
			&:nth-child(3n){
				margin-right: 0;
			}
		}
		@media(max-width: 767px){
			.gt-artikel-element-image{
				.gt-artikel-image{
					margin: 0;
				}
			}
		}
		@media(max-width: 400px){
			padding: 0 15px;
			flex-basis: 100%;
			.gt-artikel-element-image{
				.gt-artikel-image{
					width: 100%;
				}
			}
		}
	}
}
