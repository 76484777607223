
@font-face {
    font-family: 'Fira Sans';
    font-weight: 100;
    font-style: normal;
    font-display: swap;
    src: local('Fira Sans');
    src: url('../../fonts/FiraSans-Thin.ttf');
    src: url('../../fonts/FiraSans-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Fira Sans';
    font-weight: 100;
    font-style: italic;
    font-display: swap;
    src: local('Fira Sans');
    src: url('../../fonts/FiraSans-ThinItalic.ttf');
    src: url('../../fonts/FiraSans-ThinItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Fira Sans';
    font-weight: 200;
    font-style: normal;
    font-display: swap;
    src: local('Fira Sans');
    src: url('../../fonts/FiraSans-ExtraLight.ttf');
    src: url('../../fonts/FiraSans-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Fira Sans';
    font-weight: 200;
    font-style: italic;
    font-display: swap;
    src: local('Fira Sans');
    src: url('../../fonts/FiraSans-ExtraLightItalic.ttf');
    src: url('../../fonts/FiraSans-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Fira Sans';
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    src: local('Fira Sans');
    src: url('../../fonts/FiraSans-Light.ttf');
    src: url('../../fonts/FiraSans-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Fira Sans';
    font-weight: 300;
    font-style: italic;
    font-display: swap;
    src: local('Fira Sans');
    src: url('../../fonts/FiraSans-LightItalic.ttf');
    src: url('../../fonts/FiraSans-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Fira Sans';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: local('Fira Sans');
    src: url('../../fonts/FiraSans-Regular.ttf');
    src: url('../../fonts/FiraSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Fira Sans';
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: local('Fira Sans');
    src: url('../../fonts/FiraSans-Medium.ttf');
    src: url('../../fonts/FiraSans-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Fira Sans';
    font-weight: 500;
    font-style: italic;
    font-display: swap;
    src: local('Fira Sans');
    src: url('../../fonts/FiraSans-MediumItalic.ttf');
    src: url('../../fonts/FiraSans-MediumItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Fira Sans';
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    src: local('Fira Sans');
    src: url('../../fonts/FiraSans-SemiBold.ttf');
    src: url('../../fonts/FiraSans-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Fira Sans';
    font-weight: 600;
    font-style: italic;
    font-display: swap;
    src: local('Fira Sans');
    src: url('../../fonts/FiraSans-SemiBoldItalic.ttf');
    src: url('../../fonts/FiraSans-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Fira Sans';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: local('Fira Sans');
    src: url('../../fonts/FiraSans-Bold.ttf');
    src: url('../../fonts/FiraSans-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Fira Sans';
    font-weight: 700;
    font-style: italic;
    font-display: swap;
    src: local('Fira Sans');
    src: url('../../fonts/FiraSans-BoldItalic.ttf');
    src: url('../../fonts/FiraSans-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Fira Sans';
    font-weight: 800;
    font-style: normal;
    font-display: swap;
    src: local('Fira Sans');
    src: url('../../fonts/FiraSans-ExtraBold.ttf');
    src: url('../../fonts/FiraSans-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Fira Sans';
    font-weight: 800;
    font-style: italic;
    font-display: swap;
    src: local('Fira Sans');
    src: url('../../fonts/FiraSans-ExtraBoldItalic.ttf');
    src: url('../../fonts/FiraSans-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Fira Sans';
    font-weight: 900;
    font-style: normal;
    font-display: swap;
    src: local('Fira Sans');
    src: url('../../fonts/FiraSans-Black.ttf');
    src: url('../../fonts/FiraSans-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Fira Sans';
    font-weight: 900;
    font-style: italic;
    font-display: swap;
    src: local('Fira Sans');
    src: url('../../fonts/FiraSans-BlackItalic.ttf');
    src: url('../../fonts/FiraSans-BlackItalic.ttf') format('truetype');
}

@font-face{
    font-family:"Solido Condensed ExtraBold";
    src:url("../../fonts/5191748/46f373e5-29db-48d5-bfd1-6c93b4147809.woff2") format("woff2");
    src:url("../../fonts/5191748/b13ed7fd-1f5e-4300-8044-eea801e010ee.woff") format("woff");
    font-display: swap
}
@font-face{
    font-family:"Solido Constricted Black";
    src:url("../../fonts/5203713/c1215d2e-0fda-4641-97d0-c930cf3914a1.woff2") format("woff2");
    src:url("../../fonts/5203713/d09a4592-e2dd-4c36-aa0b-61b08130308f.woff") format("woff");
    font-display: swap
}
@font-face{
    font-family:"Solido Book";
    src:url("../../fonts/5204591/7327bc85-8258-4f2b-a8aa-336cbea4e0b4.woff2") format("woff2");
    src:url("../../fonts/5204591/d8284170-0d03-4b61-8813-7ee3421f66b3.woff") format("woff");
    font-display: swap
}
@font-face{
    font-family:"Solido Compressed Bold";
    src:url("../../fonts/5216915/0506de35-f7f9-46fe-bd6c-d454e6f52193.woff2") format("woff2");
    src:url("../../fonts/5216915/a73b4e2b-63c4-4cc7-88c2-7d62d0e01c95.woff") format("woff");
    font-display: swap
}
@font-face{
    font-family:"Solido Bold";
    src:url("../../fonts/5313185/ac7a1fff-4d89-43a9-9709-1c5d0db2e15b.woff2") format("woff2");
    src:url("../../fonts/5313185/35fe85a6-ee73-4e6f-8daa-52878a81a1f5.woff") format("woff");
    font-display: swap
}

@font-face {
    font-family: 'Phosphate';
    src: url('../../fonts/phosphatesolid-webfont.woff2') format('woff2'),
         url('../../fonts/phosphatesolid-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
