.wr-paginator {
	list-style: none;
	font-size: 0;
	line-height: 0;
	margin: 0;
	padding: 0;
	li {
		display: inline-block;
		font-size: 1rem;
		line-height: 1.5;
		a {
			position: relative;
			font-size: 1.875em;
			color: $color-text;
			text-decoration: none;
			padding: 0 5px;
			margin: 0 5px;
			&:after {
				position: absolute;
				display: block;
				content: '';
				left: 0;
				bottom: 0;
				height: 2px;
				width: 100%;
				border: 0;
				margin: 0;
				transform-origin: center;
				transform: scaleX(0);
				transition: .3s all;
			}
			&:hover {
				color: gray;
				&:after {
					background: gray;
					transform: scaleX(1);
				}
			}
		}
		&.selected a {
			color: $color-primary;
			&:after {
				background: $color-primary;
				transform: scaleX(1);
			}
		}
		&.prev,
		&.next {
			a {
				padding: 0 10px; 
				font-size: 1.2em;
				&:hover {
					color: $color-primary;
				}
			}
			a:after {
				content: none;
			}
		}

	}
	@media only screen and (max-width: 479px) {
		li > a {
			padding: 0 5px;
			margin: 0 2px; 
		}
	}
}