.lb-disable-scrolling {
	overflow: hidden;
	position: fixed;
	height: 100vh;
	width: 100vw;
	transition: .5s all;
}
.lb-blur {
	filter: blur(5px);
	transition: .5s all;
}
.lightboxOverlay {
	position: fixed;
	max-height: 100vh;
	max-width: 100vw;
	top: 0;
	left: 0;
	z-index: 9999;
	background-color: black;
	filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
	opacity: 0.8;
	display: none;
}
.lightbox {
	position: fixed;
	top: 0 !important;
	display: flex; 
	align-items: center;
	height: 100vh; 
	width: 100vw;
	z-index: 10000;
	text-align: center;
	line-height: 0;
	font-weight: normal;
	.lb-img-wrapper {
		display: inline-block;
		text-align: right;
		position: relative;
		margin-bottom: 0;
		figcaption {
			position: absolute;
			bottom: 15px;
			right: 20px;
			font-size: 0.8em;
			line-height: 1.2;
			font-weight: 400;
			color: #ffffff;
			// padding: 15px 20px; 
		}
	}
	.lb-image {
		display: block;
		margin: 0 auto; 
		max-width: 100%;
		height: auto !important;
		width: 100%;
		max-height: none;
		border-radius: 3px;
		// border: 4px solid white;
	}
	a {
		img {
			border: none;
		}
	}
}
.lb-outerContainer {
	position: relative;
	*zoom: 1;
	width: 100vw !important; 
	max-width: 100%;
	height: auto !important;
	min-height: 75px;
	border-radius: 4px;
	// background-color: white;
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}
.lb-loader {
	position: absolute;
	top: 43%;
	left: 0;
	height: 25%;
	width: 100%;
	text-align: center;
	line-height: 0;
}
.lb-cancel {
	display: block;
	width: 32px;
	height: 32px;
	margin: 0 auto;
	background: url(../../images/loader.gif) no-repeat;
}
.lb-nav {
	position: absolute;
	top: 50%;
	left: 0;
	height: 25%;
	width: 100%;
	transform: translateY(-50%);
	z-index: 10;
	a {
		outline: none;
		background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
		&.lb-prev {
			width: 15%;
			min-width: 50px;
			left: 0;
			position: relative;
			float: left;
			// background: url(../images/prev.png) left 48% no-repeat;
			filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
			opacity: 0.75;
			-webkit-transition: opacity 0.6s;
			-moz-transition: opacity 0.6s;
			-o-transition: opacity 0.6s;
			transition: opacity 0.6s;
			text-indent: -5px;
			&:after {
				position: absolute;
				content: '';
				top: 50%;
				left: 50%;
				width: 65px;
				height: 65px;
				border-radius: 50%;
				font-size: 40px;
				line-height: 65px;
				color: $color-text;
				background: #fff;
				transform: translate(-50%, -50%);
			}
			&:hover {
				filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
				opacity: 1;
				&:after {
					color: $color-primary;
				}
			}
		}
		&.lb-next {
			position: relative;
			right: 0;
			width: 15%;
			min-width: 50px;
			float: right;
			filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
			opacity: 0.75;
			-webkit-transition: opacity 0.6s;
			-moz-transition: opacity 0.6s;
			-o-transition: opacity 0.6s;
			transition: opacity 0.6s;
			text-indent: 5px;
			&:after {
				position: absolute;
				content: '';
				top: 50%;
				left: 50%;
				width: 65px;
				height: 65px;
				border-radius: 50%;
				font-size: 40px;
				line-height: 65px;
				color: $color-text;
				background: #fff;
				transform: translate(-50%, -50%);
				
			}
			&:hover {
				filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
				opacity: 1;
				&:after {
					color: $color-primary;
				}
			}
		}
	}
}
.lb-container {
	& > .nav {
		left: 0;
	}
}
.lb-prev, .lb-next {
	height: 100%;
	cursor: pointer;
	display: block;
}
.lb-dataContainer {
	position: fixed;
	top: 0;
	margin: 0 auto;
	padding-top: 5px;
	*zoom: 1;
	max-width: 100%;
	width: 100vw !important;
	height: 100vh !important; 
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px; 
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}
.lb-data {
	height: 100%; 
	position: relative;
	padding: 60px 60px 0;

	.lb-details {
		width: 85%;
		float: left;
		text-align: left;
		line-height: 1.1em;
		padding-left: 30px;
	}
	.lb-info-icon {
		visibility: hidden;
		opacity: 0;
		font-size: 32px;
		line-height: 1;
		color: #fff;
		font-style: normal;
		pointer-events: none;
		transition: .3s all;
		&:after {
			content: '';
		}
		&.enabled {
			opacity: 1;
			visibility: visible;
			pointer-events: all;
			cursor: pointer;
		}
		
	}
	.lb-info-wrapper {
		position: absolute;
		bottom: 0;
		left: 0; 
		width: 100%;
		max-height: 0;
		text-align: center;
		background: rgba(0,0,0,0.25);
		opacity: 0;
		transition: 1s max-height, .4s opacity;
		&.active {
			max-height: 100%;
			opacity: 1;
		}
		.container {
			position: relative;
			height: 100%;
		}
		.lb-info-content {
			padding: 15px 0;
			color: #ffffff;
			font-weight: 400;
			font-size: 1.125em;
			line-height: 1.6;
			text-align: left;
			vertical-align: top;
			&:after {
				content: '';
				display: block;
				clear: both;
			}
			.lb-info-close-icon {
				float: left;
				vertical-align: top;
				&:after {
					content: '';
					display: block;
					font-size: 12px;
					line-height: 2.5;
					color: #fff;
					font-style: normal;
					cursor: pointer;
					width: 30px; 
				}
			}
			.lb-info {
				float: left;
				max-width: calc(100% - 30px);
				vertical-align: top;
			}
		}
	}
	.lb-caption {
		font-size: 13px;
		font-weight: bold;
		line-height: 1em;
		color: #fff;
		a {
			color: $color-primary;
		}
	}
	.lb-number {
		display: block;
		clear: left;
		padding-bottom: 1em;
		font-size: 1.125em;
		font-weight: 700;
		line-height: 1;
		color: #ffffff;
	}
	.lb-close {
		display: block;
		float: right;
		width: 10%;
		height: 30px;
		text-align: center;
		outline: none;
		filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
		-webkit-transition: all 0.2s;
		-moz-transition: all 0.2s;
		-o-transition: all 0.2s;
		transition: all 0.2s;
		position: relative; 
		&:after {
			content: '';
			display: block;
			font-size: 30px;
			line-height: 1;
			color: #fff;
			transition: .3s all;
			
		}
		&:hover {
			cursor: pointer;
			filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
			opacity: 1;
			&:after {
				color: $color-primary;
			}
		}
	}
}
