/* ------------------------------------------------------------ *\
	#blocks
\* ------------------------------------------------------------ */

.gt-infographic {
	background: $color-primary;
	margin-bottom: 100px;
	padding: 75px 0;
	text-align: center;
	.title {
		margin-bottom: 50px;
		text-align: left;
		font-family: "Solido Compressed Bold";
		font-size: 77px;
		line-height: 0.99;
		letter-spacing: -1px;
		@media(max-width: $screen-md-max) {
			padding-top: 50px;
			font-size: 57px;
		}
		@media(max-width: $screen-xs-max) {
			font-size: 37px;
		}
	}
	.subheading {
		width: 65%;
		margin-bottom: 25px;
		text-align: left;
		font-family: "Solido Condensed ExtraBold";
		font-size: 34px;
		line-height: 1.18;
		letter-spacing: -0.25px;
		@media(max-width: $screen-md-max) {
			width: 70%;
			font-size: 30px;
			line-height: 1.25;
		}
		@media(max-width: $screen-xs-max) {
			width: 100%;
			font-size: 22px;
		}
	}
	.richtext {
		text-align: left;
		font-size: 28px;
		line-height: 1.36;
		@media(max-width: $screen-md-max) {
			font-size: 24px;
		}
		@media(max-width: $screen-xs-max) {
			font-size: 20px;
		}
	}
	> div {
		margin-bottom: 75px;
	}
	img {
		text-align: center;
	}
	.gt-infographic-schema {
		margin: 80px 92px 0;
		text-align: left;
		svg {
			fill: #000;
			&.icon-technician {
				fill: #fff;
			}
		}
		.gt-row {
			text-align: center;
			margin-bottom: 20px;	
			p {
				margin-right: 40px;
				font-family: "Solido Constricted Black";
				font-size: 34px;
				&.animated {
				}
				@media(max-width: $screen-md-max) {
					font-family: "Solido Compressed Bold";
					font-size: 33px;
					line-height: 1;
					letter-spacing: -0.65px;
				}
				@media(max-width: $screen-xs-max) {
					font-size: 29px;
					margin-right: 0;
					margin-bottom: 30px;
				}
			}
			.animated {
				float: left;
				opacity: 0;
				transition: 1s all;
				> svg {
					width: 110px;
					height: 140px;
					margin-bottom: 20px;
					&.icon-intern {
						width: 117px;
						height: 131px;
						margin-top: 8px;
						@media(max-width: $screen-md-max) {
							width: 85px;
							height: 95px;
						}
					}
					&.icon-master {
						width: 111px;
						height: 139px;
						@media(max-width: $screen-md-max) {
							height: 97px;
							width: 78px;
						}
						@media(max-width: $screen-xs-max) {
    						margin: 20px 10px;
    					}
					}
					&.icon-technician {
						width: 128px;
						height: 120px;
						margin-top: 19px;
						@media(max-width: $screen-md-max) {
							height: 85px;
							width: 90px;
						}
					}
					&.icon-student {
						width: 134px;
						height: 108px;
						margin-top: -25px;
						@media(max-width: $screen-md-max) {
							height: 76px;
							width: 94px;
						}
						@media(max-width: $screen-xs-max) {
							margin-top: 20px;
							height: 76px;
							width: 94px;
						}
					}
				}
				& p {
					margin-right: 0;
					font-family: "Solido Compressed Bold";
					font-size: 45px;
					line-height: 0.78;
					letter-spacing: -0.5px; 
					@media(max-width: $screen-md-max) {
						font-size: 33px;
					}
					@media(max-width: $screen-xs-max) {
						margin-bottom: 0;
						font-size: 30px;
					}
				}
				&.revealed {
					margin-top: -10px;
					opacity: 1;
				}
				@media(max-width: $screen-xs-max) {
					float: unset;
				}
			}
			.icon-med-full {
				margin: 65px 15px 0;
				height: 24px;
				width: 125px;
				&+ .icon-med-full {
					display: none;
					@media(max-width: $screen-xs-max) {
						display: block;
    					margin: 40px 15px;
					}
				}
				&:nth-of-type(1) {
					margin: 65px 30px 0 15px;
					@media(max-width: $screen-md-max) {
						margin: 45px 20px 0 0;
					}
				}
				&:nth-of-type(3) {
					margin: 65px 15px 0 30px;
					@media(max-width: $screen-md-max) {
						margin: 45px 0 0 20px;
					}
				}
				@media(max-width: $screen-md-max) {
					height: 17px;
					width: 89px;
				}
				@media(max-width: $screen-xs-max) {
					display: none;
					width: 70px;
					transform: rotate(90deg);
				}
			}
			.icon-small-punct {
				height: 100px;
				width: 24px;
				margin-left: 250px;
				&+ .icon-small-punct {
					display: none;
					@media(max-width: $screen-xs-max) {
						display: block;
    					margin: 155px 20px;
					}
				}
				@media(max-width: $screen-md-max) {
					height: 71px;
					width: 17px;
					margin-left: 180px;
				}
				@media(max-width: $screen-xs-max) {
					display: none;
					height: 30px;
					margin-left: 0;
					transform: rotate(-90deg);
				}
			}
			.icon-med-punct {
				margin-left: 305px;
				height: 155px;
				width: 24px;
				&+ .icon-med-punct {
					display: none;
					@media(max-width: $screen-xs-max) {
						display: block;
    					margin: 50px 30px;
					}
				}
				@media(max-width: $screen-md-max) {
					height: 111px;
					width: 17px;
					margin-left: 210px;
				}
				@media(max-width: $screen-xs-max) {
					display: none;
					height: 70px;
					margin-left: 0;
					transform: rotate(-90deg);
					&:last-child {
						margin: 220px 30px;
					}
				}
			}
			.icon-big-punct {
				height: 24px;
				width: 565px;
				margin-left: 40px;
				@media(max-width: $screen-md-max) {
					height: 18px;
					width: 411px;
					margin-left: 10px;
				}
				@media(max-width: $screen-xs-max) {
					margin-left: -160px;
					transform: rotate(90deg);
					&.revealed {
						margin-top: 215px;
					}
				}
			}
			&:nth-child(1) {
				& > p {
					padding-top: 55px;
				}
				@media(max-width: $screen-xs-max) {
					width: 45%;
				}
			}		
			&:nth-child(2) {
				@media(max-width: $screen-xs-max) {
					width: 20%;
				}
			}
			&:nth-child(3) {
				& > p {
					padding-top: 55px;
				}
				margin-top: -90px;
				@media(max-width: $screen-xs-max) {
					margin-top: 0;
					width: 35%;
				}
			}
			@media (min-width: $screen-xs-max) {
				&:last-child {
					.animated {
						margin-top: 0;
					}
				}
			}
			@media(max-width: $screen-xs-max) {
				float: left;
			}
		}
		@media(max-width: $screen-md-max) {
			margin: 60px auto 0;
			width: 708px;
		}
		@media(max-width: $screen-xs-max) {
			margin: 60px auto 0;
			width: 242px;
		}
	}
	@media(max-width: $screen-md-max) {
		margin: 0 -30px 50px;
		padding: 0 30px 50px;
	}
	@media(max-width: $screen-xs-max) {
		margin: 0 -30px;
		padding: 0 30px;
	}
}

.gt-important {
	padding-top: 90px;
}

.gt-info-block {
	margin-bottom: 75px;
	color: #000;
	.gt-secondary-heading {
		width: 750px;
		margin: 0 auto 90px;
		@media(max-width: $screen-md-max) {
			width: 710px;
			margin: 0 auto 70px;
		}
		@media(max-width: $screen-xs-max) {
			width: 430px;
		}
		@media(max-width: $screen-xs-min) {
			width: 280px;
		}
	}
	.content {
		p {
			font-size: 28px;
			line-height: 1.36;
			margin-bottom: 50px;
			a {
				font-weight: 700;
			}
			@media(max-width: $screen-md-max) {
				font-size: 24px;
				margin-bottom: 20px;
				~ h3 {
					margin-top: 40px;
				}
			}
			@media(max-width: $screen-xs-max) {
				font-size: 20px;
				~ h3 {
					margin-top: 30px;
				}
			}
		}
		h2 {
			margin-bottom: 15px;
			font-family: "Solido Compressed Bold";
			font-size: 77px;
			line-height: 0.99;
			letter-spacing: -1px;
			@media(max-width: $screen-xs-max) {
				font-size: 37px;
			}
		}
		h3 {
			margin-bottom: 30px;
			font-family: "Solido Condensed ExtraBold";
			font-size: 50px;
			line-height: 0.98;
			letter-spacing: -0.71px;
			text-transform: uppercase;
			&:first-of-type {
				width: 85%;
				@media(max-width: $screen-md-max) {
					width: 100%;
				}
			} 
			@media(max-width: $screen-md-max) {
				margin-bottom: 20px;
				font-size: 37px;
			}
			@media(max-width: $screen-xs-max) {
				width: 430px;
				font-size: 32px;
			}
			@media(max-width: $screen-xs-min) {
				width: 280px;
			}
		}
		h4 {
			font-family: "Solido Condensed ExtraBold";
			font-size: 34px;
			line-height: 1.18;
			letter-spacing: -0.25px;
			@media(max-width: $screen-md-max) {
				font-size: 30px;
			}
			@media(max-width: $screen-xs-max) {
				margin-bottom: 10px;
				font-size: 27px;
			}
		}
		h5 {
			margin-bottom: 50px;
			font-family: "Solido Condensed ExtraBold";
			font-size: 28px;
			line-height: 1.07;
			letter-spacing: -0.2px;
			@media(max-width: $screen-md-max) {
				font-size: 26px;
			}
			@media(max-width: $screen-xs-max) {
				width: 280px;
				font-size: 22px;
			}
		}
		ul {
			padding-inline-start: 40px;
			margin-bottom: 50px;
			li {
				font-size: 28px;
				position: relative;
				&:before {
				    content:"";
				    position: absolute;
				    top: 15px;
				    left: -20px;
				    background: #000;
				    height: 3px;
				    width: 3px;
				    border-radius: 50%;
					@media(max-width: $screen-xs-max) {
						top: 10px;
					}
				}
				@media(max-width: $screen-md-max) {
					font-size: 24px;
				}
				@media(max-width: $screen-xs-max) {
					font-size: 20px;
				}
			}
		}
		> div {
			&:first-child {
				padding-right: 75px;
				border-right: 1px solid #000;
				@media(max-width: $screen-md-max) {
					padding: 0 0 70px 0;
					margin-bottom: 70px;
					border-right: unset;
					border-bottom: 1px solid #000;
				}
			}
			&:last-child {
				padding-left: 50px;
				@media(max-width: $screen-md-max) {
					padding: 0;
				}
			}
		}
		.num-group {
			@media(max-width: $screen-md-max) {
				float: left;
				width: 50%;
			}
			@media(max-width: $screen-xs-max) {
				float: unset;
				width: 100%;
				text-align: center;
			}
			@media(max-width: $screen-xs-min) {
				text-align: left;
			}
		}
		@media(max-width: $screen-md-max) {
			margin: 0;
		}
	}
	&.gt-datenschutz-block {
		.content {
			> div {
				&:first-child {
					border-right: unset;
				}
			}
		}
	}
}

.gt-additional-offers {
	margin-bottom: 200px;
	.section-title {
		margin-bottom: 70px;
		font-family: "Solido Compressed Bold";
		font-size: 90px;
		line-height: 0.98;
		letter-spacing: -1.25px;
		text-transform: uppercase;
		@media(max-width: $screen-md-max) {
			font-size: 75px;
		}
		@media(max-width: $screen-xs-max) {
			margin-bottom: 30px;
			font-size: 52px;
		}
	}
	.section-subtitle {
		margin-bottom: 100px;
		font-family: "Solido Condensed ExtraBold";
		font-size: 34px;
		line-height: 1.18;
		letter-spacing: -0.25px;
		text-transform: uppercase;
		@media(max-width: $screen-md-max) {
			font-size: 28px;
		}
		@media(max-width: $screen-xs-max) {
			margin-bottom: 50px;
		}
	}
	.offers-list {
		li {
			padding: 30px;
			border-bottom: 1px solid #000;
			.offer-title {
				float: left;
				width: 80%;
				font-family: "Solido Constricted Black";
				font-size: 28px;
				line-height: 1.07;
				letter-spacing: -0.2px;
				@media(max-width: $screen-md-max) {
					font-family: "Solido Condensed ExtraBold";
					font-size: 26px;
				}
				@media(max-width: $screen-xs-max) {
					display: block;
					width: 100%;
					margin-bottom: 10px;
					float: unset;
				}
			}
			.link {
				float: right;
				@media(max-width: $screen-xs-max) {
					display: block;
					float: unset;
				}
			}
			&:nth-child(1) {
				border-top: 1px solid #000;
			}
		}
	}
	@media(max-width: $screen-md-max) {
		padding: 0 5px;
	}
	@media(max-width: $screen-xs-max) {
		padding: 0 15px;
	}
}