/* ======================== #footer ======================== */
.gt-footer {
	.gt-footer-top {
		padding: 100px 0 50px 0;
		border-bottom: 2px solid #000;
		& > div {
			padding: 0;
		}
		.gt-footer-partner {
			.content {
				width: 49%;
				float: left;
				font-size: 18px;
				line-height: 1.22;
				@media(max-width: $screen-md-max) {
					font-size: 16px;
				}
				@media(max-width: $screen-xs-max) {
					float: none;
					width: 100%;
					font-size: 14px;
					margin-bottom: 30px;
				}
			}
			.footer-logo {
				width: 45%;
				float: left;
				margin-right: 15px;
				object-fit: cover;
				@media(max-width: $screen-md-max) {
					width: 160px;
				}
				@media(max-width: $screen-xs-max) {
					float: none;
					width: 140px;
					margin-bottom: 20px;
				}
			}
			@media(max-width: $screen-md-max) {
				margin-bottom: 30px;
			}
		}
		.gt-footer-social-links {
			text-align: right;
			li {
				display: inline-block;
				padding: 0 15px;
				a {
					display: block;
					.icon {
						height: 35px;
						width: 35px;
						@media(max-width: $screen-md-max) {
							height: 30px;
							width: 30px;
						}
					}
				}
				@media(max-width: $screen-md-max) {
					padding: 0 5px;
				}
				@media(max-width: $screen-xs-max) {
				    padding: 0 40px 0 0;
				    &:last-child {
				    	padding: 0;
				    }
				}
			}
			@media(max-width: $screen-md-max) {
				text-align: center;
			}
		}
		@media(max-width: $screen-xs-max) {
			margin: 0 15px;
			padding: 0 0 50px 0;
			border-bottom: 1px solid #000;
		}
	}
	.gt-footer-bottom {
		padding: 30px 0;
		.footer-copyright {
			font-size: 12px;
			@media(max-width: $screen-md-max) {
				font-size: 9px;
			}
		}
		.gt-footer-nav {
			li {
				display: inline-block;
				padding-left: 30px;
				line-height: 1.26;
				font-size: 12px;
				line-height: 1.26;
				@media(max-width: $screen-md-max) {
					font-size: 9px;
				}
				@media(max-width: $screen-xs-max) {
					&:nth-child(1) {
						padding-left: 0;
					}
				}
			}
		}
		div {
			padding: 0;
			&:nth-child(1) {
				text-align: left;
				margin-bottom: 15px;
			}
			&:nth-child(2) {
				text-align: right;
				@media(max-width: $screen-xs-max) {
					text-align: left;
				}
			}
			@media(max-width: $screen-xs-max) {
				width: 100%;
			}
		}
		@media(max-width: $screen-xs-max) {
			margin: 0 15px;
		}
	}
	@media(max-width: $screen-md-max) {
		padding: 0 30px;
	}
	@media(max-width: $screen-xs-max) {
		padding: 0;
	}
}