/* ------------------------------------------------------------ *\
	#settings
\* ------------------------------------------------------------ */
* { box-sizing: border-box; }
.js-container { position: relative; overflow: hidden; }
.container {
	width: $container-width;
	@media (max-width: $screen-md-max){
		width: 100%;
	}
}
.lg-container {
	max-width: 1440px;
	margin: 0 auto;
	@media (max-width: $screen-md-max){
		max-width: 100%;
	}
}
.gt-main {
	max-width: $wrapper-width;
	margin: 0 auto;
	position: relative;
	@media (max-width: $screen-md-max){
		max-width: 100%;
	}
}

.map-container {
	position: relative;
	.zoom-controls {
		position: absolute;
		top: 30px;
		left: 30px;
		div {
			display: block;
			text-align: center;
			padding: 0 10px;
			margin-bottom: 10px;
			color: #fff;
			background: #000;
			font-size: 45px;
			line-height: 1;
			cursor: pointer;
		}
	}
	img {
		width: 100%;
		object-fit: cover;
	}
}
body {
	overflow-y: scroll;
	font-family: $font-primary;
	color: $color-text;
	&.no-scroll {
		overflow: hidden;
	}
}
a {
	color: $color-text;
	&.link {
		font-family: "Phosphate";
		font-size: 24px;
		line-height: 1;
		border-bottom: 2px solid transparent;
		&:after {
		  	display: inline-block;
			content: '';
			background-image: url('../../images/text-link-arrow.svg');
			background-size: 28px 28px;
			height: 28px;
			width: 28px;
			margin-left: 25px;
			vertical-align: bottom;
			@media (max-width: $screen-xs-max){
				background-size: 23px 23px;
				height: 23px;
				width: 23px;
				margin-left: 15px;
			}
		}
		&:hover {
			color: $color-text;
			border-bottom: 2px solid #000;
			text-decoration: none;
		}
		@media (max-width: $screen-md-max){
			font-size: 22px;
			letter-spacing: 1.5px;
		}
		@media (max-width: $screen-xs-max){
			font-size: 18px;
		}
	}
	&:hover,
	&:focus {
		color: $color-text;
		text-decoration: none;	
	}
}
ul { padding: 0; list-style: none; }
h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
p {
	line-height: 1.42857;
	margin: 0;
	word-break: break-word;
}
ul {
	margin: 0;
}
.wrapper {
	max-width: 1200px;
	margin: 0 auto;
}

.icon {
	fill: #dbd2ed;
}
figure {
	position: relative;
}
/* ------------------------------------------------------------ *\
	#z-index
\* ------------------------------------------------------------ */
.header,
.footer {
	position: relative;
	width: 100%;
}
.header {
	z-index: 40;
}
.main {
	z-index: 30;
}
.footer {
	z-index: 20;
}
.gt-accents {
	z-index: 10;
}
